.attempted-test-component-questions-solutions{
  display: flex;
  padding: 6px 0px;
.attempted-test-component-marks-solutions{
  // padding-right: 8px;
  font-weight: 500;
  font-size: 11px;
  // margin-top: 3px;
  color: #37434E;
  .ant-divider-vertical{
    margin-top: 3px !important;
    background: #7A8B94 !important;
  }
}
.attempted-test-component-date-solutions{
  font-weight: 500;
  font-size: 11px;
  // margin-top: 4px;
  color: #37434E;
  // padding-left: 8px;
  .ant-divider-vertical{
    margin-top: 3px;
    background: #7A8B94 !important;
  }
}
}
.attempted-test-component{
  display: flex;
  // width: 49%;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #E1E7EA;
  margin: 5px 10px 5px 0px;
  border-radius: 5px;
  padding: 10px 20px;
  .attempted-test-content{
    .exam-title{
      text-transform: capitalize;
    }
  }

  .view-solutions{
    display: flex;
    align-items: center;
    .ant-btn{
      background: #FFFFFF;
      border: 1px solid #2D81F7;
      border-radius: 3px;
      span {
        color: #2D81F7; 
        font-weight: 500;
        font-size: 13px;     
    }
  }
  }
  p{
    margin: 0px;
  }
  .attempted-test-component-test{
    font-weight: 600;
    font-size: 14px;
    color: #021F55;
    padding-bottom: 3px;
    padding-top: 3px;
    text-transform: capitalize;
  }
}

.attempted-test-header{
  margin:16px 0px;
  background: #FFFFFF;
  border: 1px solid #E1E7EA;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0px 25px;
  align-items: center;
  p{
    margin: 0px;
    // padding: 42px 0px 42px 25px;
    font-weight: 600;
    font-size: 22px;
    color: #1B2228;
  }
  .ant-image{
    margin: 3px 24px 4px 0px;
  }
}
.overall-attempted-test{
  .ant-tabs-nav{
    // width: 47%;
    height: 33px;
    .ant-tabs-tab{
      margin: 0px;
      padding: 8px 21px;
      // margin: 0 0 0 21px !important;
    }
    .ant-tabs-tab-btn{
      font-weight: 500;
      font-size: 14px;
      color: #60717A;
    }
  }
  .ant-tabs-tab {
    background-color:#ffff;
  }
}

.exam-title{
  margin-top: 5px;
  font-weight: 500;
  font-size: 10px;
  // color: #2D81F7;
  // color: #021F55;
  color: #1B2228;


  text-transform: capitalize;

  .full-test-title{
      // margin-right: 8px;
      color: #D06200 !important;
      font-weight: 500;
      font-size: 10px;
      // background: rgba(252, 204, 186, 0.1);
      // border: 1px solid #E1C5BB;
      border-radius: 2px;
      // padding: 4px 0px;
      text-transform: capitalize;

  }
  .subject-title{ 
    color: #039464 !important;
    // margin-right: 8px;
    font-weight: 600;
    font-size: 10px;
    // background: rgba(7, 189, 129, 0.05);
    // border: 1px solid rgba(8, 151, 104, 0.2);
    border-radius: 2px;
    // padding: 4px 6px;
    text-transform: capitalize;

  }
  .chapter-title{
      // margin-right: 8px;
      color: #5D0AAA;
      font-weight: 600;
      font-size: 10px;
      // background: rgba(155, 81, 224, 0.05);
      // border: 1px solid rgba(155, 81, 224, 0.4);
      border-radius: 2px;
      // padding: 4px 6px;
      text-transform: capitalize;

  }
}
.attented-test-wrapper{
  overflow-y: scroll;
  height: calc(100vh - 322px);
}
// ::-webkit-scrollbar {
//   display: none !important; 
// }




/*************** Mobile Responsive Styles **************/
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  /* CSS */
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
.overall-attempted-test{
  padding: 12px;
  .attempted-test-header{
    padding: 0px 12px;
    p{
      font-size: 16px;
      width: 70%;
      text-align: left;
    }
    .ant-image{
      width: 30%;
      margin: 0px;
      display: flex;
      justify-content: flex-end;
      
    }
  }
  .empty-container{
    margin-top: 50px;
    .empty-content{
      .ant-image{
        display: flex;
        justify-content: center;
        img{
          width: 70%;
        }
      }
      .empty-text{
        font-size: 14px;
      }
    }
  }
}
.availableTest-container{
  padding: 12px!important;
  .availableTest-header{
    padding: 0px 12px!important;
    .header-text{
      font-size: 16px!important;
      width: 70%;
      text-align: left;
    }
    .ant-image{
      width: 30%;
      display: flex;
      justify-content: flex-end;
    }
  }
  .empty-container{
    margin-top: 50px;
    .empty-content{
      .ant-image{
        display: flex;
        justify-content: center;
        img{
          width: 70%;
        }
      }
      .empty-text{
        font-size: 14px;
      }
    }
  }
}
.attented-test-wrapper{
  .attempted-test-component{
    width: 100%;
    flex-direction: column;
    padding: 12px 15px;
    .view-solutions{
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
}
}
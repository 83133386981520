@import "../variable.scss";

.institution-stud-test-container {
  display: flex;
  // height: 100vh;
  padding: 20px 44px 59px 20px;

  .inst-dashboard-container {
    width: 100%;
    overflow: scroll;

    .today-test-container {
      margin-top: 32px;
      position: relative;

      .test-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }

      .test-card-container {

        .test-card {
          padding: 14px;
          margin-right: 2%;
          width: 100%;
          display: flex;
          align-items: center;
          height: auto;
          background: rgba(7, 189, 129, 0.12);
          border-radius: 8px;
          margin-bottom: 15px;

          p {
            margin: 0px;
          }

          .test-details-all {
            width: 33%;

            .test-exam-group-name {
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              color: #039464;
              margin-bottom: 10px;
            }

            .test-name {
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              color: #021F55;
              margin-bottom: 10px;
            }

            .test-details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              width: 75%;

              p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #37434E;
              }

              .break {
                border: 1px solid #60717A;
                height: 13px;
                width: 1px;
              }
            }
          }

          .time-language {
            width: 33%;

            .time-duration {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              .image {
                margin-right: 10px;
              }

              .text {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #37434E;
              }
            }
          }

          .take-test {
            width: 33%;
            display: flex;
            justify-content: end;

            .ant-btn {
              padding: 8px 36px;
              background: #07BD81;
              border-radius: 2px;
              color: #FFFFFF;
              height: 38px;
            }
          }
        }
      }
    }

    .assign-test-container {
      margin-top: 32px;
      position: relative;

      .test-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }

      .test-card-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .test-card {
          background: #FFFFFF;
          border: 1px solid #B8C3C9;
          border-radius: 10px;
          padding: 14px;
          margin-right: 1%;
          width: 32%;
          height: auto;
          margin-bottom: 10px;

          p {
            margin: 0px;
          }

          .test-exam-group-name {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #2D81F7;
            margin-bottom: 6px;
          }

          .test-name {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #021F55;
            margin-bottom: 10px;
          }

          .test-details {

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            width: 75%;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #37434E;
            }

            .break {
              border: 1px solid #60717A;
              height: 13px;
              width: 1px;
            }
          }


          .time-duration {
            display: flex;
            align-items: center;

            .image {
              margin-right: 10px;
            }

            .time {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #37434E;
            }
          }
        }
      }
    }

    .complete-test-container {
      margin-top: 32px;
      position: relative;

      .test-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }

      .test-card-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .test-card {
          background: #FFFFFF;
          border: 1px solid #B8C3C9;
          border-radius: 10px;
          padding: 14px;
          margin-right: 1%;
          width: 32%;
          height: auto;
          margin-bottom: 10px;

          p {
            margin: 0px;
          }

          .test-exam-group-name {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #2D81F7;
            margin-bottom: 6px;
          }

          .test-name {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #021F55;
            margin-bottom: 10px;
          }

          .test-details {

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            width: 75%;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #37434E;
            }

            .break {
              border: 1px solid #60717A;
              height: 13px;
              width: 1px;
            }
          }

          .batch,
          .language,
          .time-duration {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            .image {
              margin-right: 10px;
            }

            .text {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #37434E;
            }

          }

          .break-line {
            border: 1px solid #ddd;
            width: 100%;
            height: 1px;
            margin-bottom: 10px;
            margin-top: 15px;
          }

          .view-result {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-decoration-line: underline;
            color: #2D81F7;
          }
        }
      }
    }

  }
}

.empty-test-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 4%;
  background: #DCFCE7;
  border-radius: 10px;

  .empty-content {
    .heading {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #1B2228;
      margin-bottom: 6px;
    }

    .text {
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      color: #60717A;
      margin: 0px;
    }
  }
}
.ant-layout-content {
  margin: 0px !important;
}

.take-test {
  padding: 24px;

  .ant-tabs {
    margin: 0px !important;

    .ant-tabs-nav {
      // width: 485px;
      height: 33px;
      font-weight: 500;
      font-size: 14px;
      color: #60717A;

      .ant-tabs-tab {
        margin: 0px;
        background: #FFFFFF;

      }
    }

    .ant-tabs-tab-btn {
      padding: 7px 0px 8px 0px;
    }
  }

  // .ant-tabs-nav{
  //   background: #fff;
  //   width: 44%;
  //   margin: 16px 0px 14px 24px;
  //   padding-left: 21px;
  // font-weight: 500;
  // font-size: 14px;
  // color: #60717A;
  //   padding-left: 32px;
  // }
  .ant-tabs-top {
    // margin-left: 24px !important;
    // margin-top: 16px !important;
  }

  .ant-tabs-tab {
    padding: 15px !important;
  }

  .path {
    display: flex;
    // padding:20.5px 0px 0px 24px;
    font-weight: 500;
    font-size: 12px;
    color: #60717A;
    text-transform: capitalize;

    p {
      cursor: pointer;
      margin: 0px;
      padding-top: 2px;
      padding-right: 7px
    }
  }

  .take-test-header {
    border: 1px solid #E1E7EA;
    border-radius: 10px;
    background: #FFFFFF;
    padding: 25px;
    display: flex;
    align-items: center;
    margin: 16px 0px;

    p {
      margin: 0px;

    }

    .examName {
      font-weight: 600;
      font-size: 22px;
      padding: 0px 20px;
      text-transform: capitalize;

    }

    .ant-image {
      width: 50px;
      height: 50px;

      .ant-image-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.question-test-component {
  padding: 16px 25px;
}

.category-name {
  font-weight: 600;
  color: #1B2228;
  font-size: 24px;
  font-family: 'Montserrat';
}

.take-test-component {
  @media screen and (min-width:640px) {
    width: 100%;

  }

  @media screen and (min-width:1024px) {
    width: 60%;

  }

  background: #FFFFFF;
  border: 1px solid #E1E7EA;
  // width: 49%;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;


  .take-test-content {
    width: 80%;

    .take-test-component-test {
      font-weight: 600;
      font-size: 14px;
      color: #021F55;
      margin: 0;
      text-transform: capitalize;
    }

    .take-test-component-content {
      display: flex;
      margin-bottom: 10px;
      margin-top: 8px;

      p {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        color: #37434E;
        padding: 0px 8px 0px 8px;
        border-right: #7A8B94 1px solid;
      }

      p:nth-child(1) {
        padding-left: 0px !important;
      }

      p:nth-child(3) {
        border: none;
      }
    }
  }

  .add-to-cart {
    display: flex;
    // padding-right: 16px;
    align-items: center;

    .ant-btn>span {
      color: rgb(45, 129, 247) !important;
    }
  }
}

.subject-wrapper {
  ul {
    cursor: pointer;
    list-style: none;
    display: flex;
    padding: 0px !important;

    li {
      min-width: 56px;
      text-align: center;
      padding: 6px 16px;
      font-weight: 500;
      font-size: 12px;
      color: #37434E;
      margin-right: 10px;
      padding-right: 10px;
      background: #F9F9F9;
      border: 1px solid #E1E7EA;
      border-radius: 4px;
      padding-left: 10px;
    }
  }
}

.chapter-wrapper {
  height: calc(90vh - 246px);
  overflow-y: scroll;

  ul {
    text-transform: capitalize;
    cursor: pointer;
    list-style: none;
    display: flex;
    padding: 0px !important;
    overflow-x: scroll;

    li {
      text-align: center;
      min-width: 150px;
      padding: 6px 16px;
      font-weight: 500;
      font-size: 12px;
      color: #37434E;
      margin-right: 10px;
      padding-right: 10px;
      background: #F9F9F9;
      border: 1px solid #E1E7EA;
      border-radius: 4px;
      padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90px
    }
  }

  .test-list-empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.chapter-active {
  background: #2D81F7 !important;
  color: #F9F9F9 !important;
}

.subject-active {
  background: #2D81F7 !important;
  color: #F9F9F9 !important;
}

.add-to-cart {
  .ant-btn {
    border: 1px solid #2D81F7;
    color: #2D81F7
  }
}


/*************** Mobile Responsive Styles **************/
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

  /* CSS */

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .take-test {
    padding: 12px;

    .take-test-header {
      margin: 12px 0px !important;
      padding: 10px !important;

      .ant-image {
        img {
          width: 40px;
          height: 40px;
        }
      }

      .examName {
        font-size: 16px !important;
        padding: 0px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        padding-left: 5px !important;
      }
    }

    .take-test-component {
      width: 100%;
      margin-left: 30px;
    }

    .empty-container {
      margin-top: 50px;

      .empty-content {
        .ant-image {
          display: flex;
          justify-content: center;

          img {
            width: 70%;
          }
        }
      }
    }
  }
}


.category-card {
  border: 1px solid #E1E7EA;
  border-radius: 6px;
  background: #FFFFFF;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin-bottom: 12px;
  cursor: pointer;

  .category-name-section {
    display: flex;
    align-items: center;

    .category-name-text {
      margin-left: 10px;

      .category-name {
        font-family: 'Montserrat';
        color: #021F55;
        margin-bottom: 8px;
        font-size: 15px;
        font-weight: 500;
      }

      .number-test {
        color: #37434E;
        font-size: 13px;
        font-weight: 500;
        font-family: 'Montserrat';
        margin: 0px;
      }
    }
  }
}
@import "../variable.scss";

.institution-stud-test-container,
.institutionDashBoard-container {
  display: flex;
  // height: 100vh;
  padding: 20px 44px 59px 20px;

  .inst-dashboard-container {
    width: 100%;
    overflow: scroll;
    margin-bottom: 10px;

    .user-header-container {
      height: 98px;
      background: #FFFFFF;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;

      .user-profile-header {
        display: flex;
        width: 60%;
        padding: 23px 20px 23px 20px;
        display: flex;
        align-items: center;

        .user-first-name {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #d0d0d096;
          border-radius: 50%;

          .text-name {
            margin: 0px;
            text-transform: capitalize;
            font-weight: 600;
          }
        }

        .inst-user-details {
          padding-left: 20px;

          .inst-user-name {
            padding-bottom: 8px;
            margin: 0px !important;
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
            color: #1B2228;
          }

          .inst-user-number {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #7A8B94;
            margin: 0px;
          }
        }
      }

      .user-score-header {
        display: flex;
        justify-content: flex-end;
        padding: 0px 30.6px 9px 17.5px;

        .user-points-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #FFA443;

          .user-score-points {
            font-weight: 700;
            font-size: 28px;
            line-height: 34px;
            padding-bottom: 4px;
            margin: 0px;
          }

          .user-points-text {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            margin: 0px;
          }
        }

        .medal-image-container {
          padding-left: 37px;
        }
      }
    }

    .today-test-container,
    .assign-test-container {
      margin-top: 32px;
      position: relative;

      .test-heading {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }

      .test-card-container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .test-card {
          background: #FFFFFF;
          border: 1px solid #B8C3C9;
          border-radius: 10px;
          padding: 14px;
          margin-right: 1%;
          width: 32%;
          height: auto;
          margin-bottom: 10px;

          p {
            margin: 0px;
          }

          .test-exam-group-name {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #2D81F7;
            margin-bottom: 6px;
          }

          .test-name {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #021F55;
            margin-bottom: 10px;
          }

          .test-details {

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            width: 75%;

            p {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #37434E;
            }

            .break {
              border: 1px solid #60717A;
              height: 13px;
              width: 1px;
            }
          }


          .time-duration {
            display: flex;
            align-items: center;

            .image {
              margin-right: 10px;
            }

            .time {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: #37434E;
            }
          }
        }
      }
    }

  }
}

.empty-test-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 4%;
  background: #DCFCE7;
  border-radius: 10px;

  .empty-content {
    .heading {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #1B2228;
      margin-bottom: 6px;
    }

    .text {
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      color: #60717A;
      margin: 0px;
    }
  }
}
@media screen and (min-width:640px) {
  .overall-solutions {
    padding: 24px;

    .goLeader-btn-section {
      .goLeader-btn{
        display: flex;
        align-items: center;
        margin-left: 10px;
        .ant-btn {
          background: #2D81F7;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }
        span {
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
        }
      }
    }
    .path {
      display: flex;
      // padding:20.5px 0px 0px 42px;
      font-weight: 500;
      font-size: 12px;
      text-transform: capitalize;

      // color:#60717A;
      p:nth-child(1) {
        cursor: pointer;
        margin: 0px;
        padding-top: 3px;
        padding-right: 7px;
        color: #60717A;
      }

      p:nth-child(3) {
        cursor: pointer;
        margin: 0px;
        padding-top: 3px;
        padding-left: 7px;
        color: #021F55;
      }

      p {
        margin: 0px;
        cursor: pointer;
      }
    }

    @media screen and (min-width:640px) {
      .solutions-component {
        padding: 20px 38px 20px 16px;
        margin: 16px 0px;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;

        p {
          margin: 0px;
        }

        .solutions-content {
          .solutions-component-questions {
            display: flex;
            // align-items: center;
            margin-bottom: 10px;

            p:nth-child(1) {
              .ant-image {
                padding-left: 0px;
                padding-right: 6px;
              }
            }

            .solutions-component-marks {
              // border-right: #60717A 1px solid;
              // padding-right: 8px;
              line-height: 15px;

              .ant-image {
                padding-right: 10px;
                // padding-left: 10px;
              }

              span {
                font-weight: 500;
                font-size: 11px;
                color: #1B2228;
              }
            }

            .ant-divider-vertical {
              margin: 4px 10px 0px 6px !important;
            }
          }

          .solutions-component-test {
            margin: 10px 0px 9px 0px;
            font-weight: 600;
            font-size: 16px;
            color: #1B2228;
            text-transform: capitalize;
          }
        }

        .solution-btn-section {
          display: flex;
          align-items: center;

          .view-solutions {
            display: flex;
            align-items: center;
            margin-left: 10px;
            gap: 20px;
            .ant-btn {
              background: #2D81F7;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
              border-radius: 4px;

              span {
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }

    .overall-quiz-details {
      margin: 12px 0px 22px 0px;
      height: calc(100vh - 355px);
      overflow: scroll;

      .quiz-header {
        p {
          margin: 0px;
        }

        .header {
          font-size: 14px;
          font-weight: 600;
          color: #021F55;
          line-height: 17px;
        }
      }

      .quiz-content {
        overflow: scroll;
        margin: 16px 0px;

        // display: flex;
        // .quiz-details-card:nth-child(1){
        //   margin-left: 0px ;
        // }
        .quiz-details-card {
          // margin-left: 20px;
          display: flex;
          // justify-content: space-between;
          // width: 97%;
          background: #FFFFFF;
          border: 0.886792px solid #E1E7EA;
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 4px;
          margin-right: 4px;

          p {
            margin: 0px;
          }

          .card-details {
            padding-left: 14px;

            .card-header {
              font-weight: 600;
              font-size: 13px;
              color: #7A8B94;
            }

            .card-marks {
              font-weight: 600;
              font-size: 18px;
              color: #021F55;

              span {
                font-weight: 600;
                font-size: 12px;
                color: #7A8B94;
              }
            }
          }
        }
      }
    }

    .rank-mark {
      display: flex;

      .time-details {
        justify-content: space-between;
        background: #FFFFFF;
        border: 0.886792px solid #E1E7EA;
        border-radius: 8px;
        width: 352px;
        display: flex;
        padding: 14px 27px 11px 21px;

        ul {
          margin: 0px;
          padding: 0px;
        }

        li {
          margin: 0px !important;
          list-style: none;
        }

        .heading {
          li {
            padding-bottom: 3px;
            font-weight: 500;
            font-size: 13px;
            color: #37434E;
          }
        }

        .details {
          margin-top: 5px;

          li {
            padding-bottom: 3px;
            font-weight: 500;
            font-size: 12px;
            color: #60717A;
          }
        }
      }
    }

    .rank {
      display: flex;
      background: #FFFFFF;
      border: 1px solid #E1E7EA;
      border-radius: 6px;
      // width: 228px;
      margin-left: 10px;
      align-items: center;
      padding: 0px 46px 0px 26px;

      p {
        margin: 0px;
      }
    }

    .rank-content {
      padding-left: 20px;
      padding-top: 10px;

      .rank-heading {
        font-weight: 600;
        font-size: 16px;
        color: #37434E;
      }

      .overall-rank {
        font-weight: 500 !important;
        color: #7A8B94 !important;
        font-size: 16px !important;

        span {
          font-weight: 600;
          font-size: 26px;
          color: #FFA443;
        }
      }

      .overall-mark {
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #7A8B94 !important;

        span {
          font-weight: 600;
          font-size: 26px !important;
          color: #2D81F7;
        }
      }
    }
  }
}

.exam-title {
  margin-top: 5px;
  font-weight: 600;
  font-size: 11px;
  // color: #2D81F7;
  // color: #021F55;
  color: #1B2228;


  text-transform: capitalize;

  .full-test-title {
    // margin-right: 8px;
    color: #D06200 !important;
    font-weight: 600;
    font-size: 11px;
    // background: rgba(252, 204, 186, 0.1);
    // border: 1px solid #E1C5BB;
    border-radius: 2px;
    // padding: 4px 0px;
    text-transform: capitalize;

  }

  .subject-title {
    color: #039464 !important;
    // margin-right: 8px;
    font-weight: 600;
    font-size: 11px;
    // background: rgba(7, 189, 129, 0.05);
    // border: 1px solid rgba(8, 151, 104, 0.2);
    border-radius: 2px;
    // padding: 4px 6px;
    text-transform: capitalize;

  }

  .chapter-title {
    // margin-right: 8px;
    color: #5D0AAA;
    font-weight: 600;
    font-size: 11px;
    // background: rgba(155, 81, 224, 0.05);
    // border: 1px solid rgba(155, 81, 224, 0.4);
    border-radius: 2px;
    // padding: 4px 6px;
    text-transform: capitalize;

  }
}

@media screen and (max-width:640px) {

  .overall-solutions {
    padding: 24px;

    .goLeader-btn-section {
      .goLeader-btn{
        display: flex;
        align-items: center;
        margin-left: 10px;
        .ant-btn {
          background: #2D81F7;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }
        span {
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
        }
      }
    }
    .path {
      display: flex;
      // padding:20.5px 0px 0px 42px;
      font-weight: 500;
      font-size: 12px;
      text-transform: capitalize;

      // color:#60717A;
      p:nth-child(1) {
        cursor: pointer;
        margin: 0px;
        padding-top: 3px;
        padding-right: 7px;
        color: #60717A;
      }

      p:nth-child(3) {
        cursor: pointer;
        margin: 0px;
        padding-top: 3px;
        padding-left: 7px;
        color: #021F55;
      }

      p {
        margin: 0px;
        cursor: pointer;
      }
    }

    .solutions-component {
      padding: 20px 38px 20px 16px;
      margin: 16px 0px;
      background: #FFFFFF;
      border-radius: 6px;

      // display: flex;
      // justify-content: space-between;
      p {
        margin: 0px;
      }

      .solutions-content {
        .solutions-component-questions {
          display: flex;
          // align-items: center;
          margin-bottom: 10px;

          p:nth-child(1) {
            .ant-image {
              padding-left: 0px;
              padding-right: 6px;
            }
          }

          .solutions-component-marks {
            // border-right: #60717A 1px solid;
            // padding-right: 8px;
            line-height: 15px;

            .ant-image {
              padding-right: 10px;
              // padding-left: 10px;
            }

            span {
              font-weight: 500;
              font-size: 11px;
              color: #1B2228;
            }
          }

          .ant-divider-vertical {
            margin: 4px 10px 0px 6px !important;
          }
        }

        .solutions-component-test {
          margin: 10px 0px 9px 0px;
          font-weight: 600;
          font-size: 16px;
          color: #1B2228;
          text-transform: capitalize;
        }
      }

      .solution-btn-section {
        display: flex;
        align-items: center;

        .view-solutions {
          display: flex;
          align-items: center;
          margin-left: 10px;

          .ant-btn {
            background: #2D81F7;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 4px;

            span {
              font-weight: 500;
              font-size: 14px;
              color: #FFFFFF;
            }
          }
        }
      }

    }

    .overall-quiz-details {
      margin: 12px 0px 22px 0px;
      height: calc(100vh - 355px);
      overflow: scroll;

      .quiz-header {
        p {
          margin: 0px;
        }

        .header {
          font-size: 14px;
          font-weight: 600;
          color: #021F55;
          line-height: 17px;
        }
      }

      .quiz-content {
        overflow: scroll;
        margin: 21px 0px 22px 2px;
        display: flex;

        .quiz-details-card {
          // margin-left: 20px;
          display: flex;
          // justify-content: space-between;
          // width: 100%;
          background: #FFFFFF;
          border: 0.886792px solid #E1E7EA;
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 4px;
          margin-right: 4px;

          p {
            margin: 0px;
          }

          .card-details {
            padding-left: 14px;

            .card-header {
              font-weight: 600;
              font-size: 13px;
              color: #7A8B94;
            }

            .card-marks {
              font-weight: 600;
              font-size: 18px;
              color: #021F55;

              span {
                font-weight: 600;
                font-size: 12px;
                color: #7A8B94;
              }
            }
          }
        }
      }
    }

    .rank-mark {

      // display: flex;
      .time-details {
        justify-content: space-between;
        background: #FFFFFF;
        border: 0.886792px solid #E1E7EA;
        border-radius: 8px;
        // width: 352px;
        display: flex;
        padding: 14px 27px 11px 21px;

        ul {
          margin: 0px;
          padding: 0px;
        }

        li {
          margin: 0px !important;
          list-style: none;
        }

        .heading {
          li {
            padding-bottom: 3px;
            font-weight: 500;
            font-size: 13px;
            color: #37434E;
          }
        }

        .details {
          margin-top: 5px;

          li {
            padding-bottom: 3px;
            font-weight: 500;
            font-size: 12px;
            color: #60717A;
          }
        }
      }
    }

    .rank {
      display: flex;
      background: #FFFFFF;
      border: 1px solid #E1E7EA;
      border-radius: 6px;
      // width: 228px;
      margin-top: 10px;
      align-items: center;
      padding: 0px 46px 0px 26px;

      p {
        margin: 0px;
      }
    }

    .rank-content {
      padding-left: 20px;
      padding-top: 10px;

      .rank-heading {
        font-weight: 600;
        font-size: 16px;
        color: #37434E;
      }

      .overall-rank {
        font-weight: 500 !important;
        color: #7A8B94 !important;
        font-size: 16px !important;

        span {
          font-weight: 600;
          font-size: 26px;
          color: #FFA443;
        }
      }

      .overall-mark {
        font-weight: 500 !important;
        font-size: 16px !important;
        color: #7A8B94 !important;

        span {
          font-weight: 600;
          font-size: 26px !important;
          color: #2D81F7;
        }
      }
    }
  }
}
@import "../variable.scss";

.sign-up-content{
  p:nth-child(1){
    font-weight: 600;
    font-size: 20px;
    color: #021F55;
  } 
  p:nth-child(2){
    font-weight: 400;
    font-size: 14px;
    color: #021F55;
  }
}

.signUp{
  .ant-image-img{
    margin-top: 16%;
    margin-bottom: 28.04px;
  }
}

.input-field{
  position:'relative';
  text-align:center;
  Input{
    width: 75%;
  }
  p{
    text-align:start;
    padding-left: 12%;
    padding-bottom: 0px;
    padding-top:28px;
  }
  Button{
    width: 75%;
    margin-top: 25px;
    margin-bottom: 17px;
  }
}

.error{
  // position:absolute;
  color: red;
  text-align: start;
  // padding-left: 13%;
  // padding: 4px;
}


.signup-container{
  display: flex;
  height: 100vh;
  .signupImage{
 background: url('../assets/images/centumLoginPage.svg') repeat-x center top / cover;

    width: 50%;

  }
  .signup-wrapper{
  width: 50%;
    p{
      margin: 0px;
    }
    // padding: 30px 0px;
    margin-top: 20vh;
    .signup-content{
      text-align: center;
      .header{
        color: $primary-dark-blue;
        font-weight: 600;
        font-size: 20px;
        padding: 7px;
        margin: 0px;
      }
      .sub-header{
        color: $primary-dark-blue;
        font-weight: 400;
        font-size: 16px;
      }
    }
    .signup-form{
      width: 100%;
      display:flex;
      justify-content: center;
      align-items: center;
      .form-wrapper{
        width: 420px;
        padding: 25px 0px;
        .form-control{
          label{
            font-weight: 500;
            font-size: 12px;
            color: $primary-black;
          }
          .ant-input-password{
            width: 100% !important;
            height: 38px !important;
            padding: 0px 11px;
            margin: 6px 0px;
            .ant-input{
              height: 36px;
              margin: 0px;
            }
          }
          .ant-input{
            width: 100% !important;
            height: 38px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #1B2228;
          }
          .ant-btn{
            width: 100%;
            font-weight: 500;
            font-size: 13px;
            height: 38px;

          } 
          .ant-btn-primary{
            color: #fff;
            border-color: $primary-light-blue;
            background: $primary-light-blue;
          }
          .forgot-link{
            text-align: right;
          }
          .signup-button{
            margin: 11px 0px;
          }

  
        }
        .signIn-option{
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: $primary-black;
        }
      }

    }
  }
}
@import "../variable.scss";


/*************** Mobile Responsive Styles **************/
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  /* CSS */
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  .user-onboarding-container{
    background: url('../assets/images/login-bg.svg') no-repeat center bottom;
    .user-onboarding-image{
      display: none!important;
    }
    .user-onboarding-wrapper{
      width: 100%!important;
      padding: 0px 30px!important;
      margin-top: 15vh!important;
        .header{
          font-size: 16px!important;
        }
        .sub-header{
          font-size: 14px!important;
        }
      }
  }
  
}
.purchased-list-empty{
  .purchased-list-empty-header{
    margin: 23px 0px 0px 37.5px;
    font-weight: 600;
    font-size: 18px;
    color: #021F55;
  }
  .purchased-list-empty-image{
    margin: 88px 0px 0px 346.5px;
    p{
      font-weight: 400;
      font-size: 14px;
      color: #37434E;
      margin-top: 17px;
    }
  }
}
.purchased-list-empty-tabs{
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchased-list{
  margin: 15px 24px 16px 25px;
  p{
    margin: 0px;
  }
  .purchased-list-header{
    background: #FFFFFF;
    border: 1px solid #E1E7EA;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    p{
      padding: 42px 0px 42px 25px;
      font-weight: 600;
      font-size: 22px;
      color: #1B2228;
    }
    .ant-image{
      margin: 3px 24px 4px 0px;
    }
  }
  .purchased-list-content{
    .ant-tabs{
      margin: 0px !important;
      .ant-tabs-nav{
        width: 600px;
        height: 33px;
      background: #FFFFFF;
      .ant-tabs-tab{
        margin: 0px;
      } 
    }
  }
  .ant-tabs-tab-btn{
    padding: 0px 21px;
  }
  .ant-tabs-tab-btn{
    font-weight: 500;
    font-size: 14px;
    color: #60717A;
  }
  }
}



.purchased-list-component{
  background: #FFFFFF;
  border: 1px solid #E1E7EA;
  width: 49%;
  border-radius: 5px;
  padding: 11px 0px 0px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  .purchased-list-content{
    width: 49%;
    .purchased-list-component-test{
      font-weight: 600;
      font-size: 14px;
      color: #021F55;
      margin: 0;
    }
    .ant-divider{
      margin: 0px ;
    }
    .purchased-list-component-questions{
      display: flex;
      p:nth-child(1){
        padding-left: 0px !important;
      }
        p{
          margin: 0;
        font-weight: 500;
        font-size: 11px;
        color: #37434E;
        padding-right: 10px;
        // padding-top: 4px;
        padding-left: 8px;
      }
      .ant-image{
        margin: 0px 8px 0px 8px;
      }
    }
  }
  .take-a-test{
    display: flex;
    padding-right: 16px;
    align-items: center;
    
    .ant-btn{
      background: #07BD81;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      span {
      color: #F9F9F9;
      font-size: 13px;
      font-weight: 500;
    }
  }
  }
  .view-solutions{
    display: flex;
    padding-right: 16px;
    align-items: center;
    
    .ant-btn{
      background: #FFFFFF;
      border: 1px solid #2D81F7;
      border-radius: 3px;
      span {
        color: #2D81F7; 
        font-weight: 500;
        font-size: 13px;     
    }
  }
  }
}
.purchased-list-component-questions-solutions{
    display: flex;
  .purchased-list-component-marks-solutions{
    padding-right: 8px;
    font-weight: 500;
    font-size: 11px;
    // margin-top: 3px;
    color: #37434E;
    .ant-divider-vertical{
      margin-top: 3px !important;
      background: #7A8B94 !important;
    }
  }
  .purchased-list-component-date-solutions{
    font-weight: 500;
    font-size: 11px;
    // margin-top: 4px;
    color: #37434E;
    padding-left: 8px;
    .ant-divider-vertical{
      margin-top: 3px;
      background: #7A8B94 !important;
    }
  }
}

.exam-title{
  margin-top: 10px !important;
  font-weight: 600;
  font-size: 10px;
  // color: #2D81F7;
  // color: #021F55;
  color: #1B2228;


  margin-bottom: 6px !important;
  text-transform: capitalize;

  .full-test{
    margin-right: 8px;
    color: #D06200;
    font-weight: 600;
    font-size: 10px;
    background: rgba(252, 204, 186, 0.1);
    border: 1px solid #E1C5BB;
    border-radius: 2px;
    padding: 4px 0px;
    text-transform: capitalize;

  }
}
.ant-divider{
  margin: 0px;
} 
.overall-attempted-test{
  // margin-left: 25px;
  padding: 24px;
}


.purchased-list-empty-image-list{
  display: flex;
  justify-content: center;
  align-items: center;
}

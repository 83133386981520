.notification-container{
    .notification-header{
    background-color: #ffffff;
    border: 1px solid #e1e7ea;
    border-radius: 10px;
    padding: 25px;
    margin: 18px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        color: #1b2228;
        font-size: 22px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    }
    .body-container{
        .date{
            font-weight: 500;
            font-family: "Montserrat";
            font-size: 12px;
            line-height: 14px;
        }
        .message-container{
            // align-items: center;
            background: #fff;
            // border: 1px solid #e1e7ea;
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0 18px 12px 0;
            padding: 10px;
            width: 100%;
            .message-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .message-header-wrapper{
                    display: flex;
                    align-items: center;
                    text-wrap: nowrap;
                    gap: 12px;
                    .notification-icon{
                        width: 20px;
                        height: 20px;
                        .ant-image{
                            .ant-image-img{
                                // width: 0%;
                            }
                        }
                    }
                    .message-title{
                        font-family: "Montserrat";
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 14px;
                    }
                }

                .time{
                    font-size: 12px;
                    font-weight: 400;
                    font-family:"Montserrat" ;
                    line-height: 18px;
                }
                
            }
            .message-content{
                font-family: "Montserrat";
                font-size: 13px;
                font-weight: 400;
                line-height: 20px; 
                margin-left: 30px;
            }
        }
    }
}
.ant-drawer-body{
    background: #f0f2f5;
}
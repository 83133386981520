.landing-page {
  display: flex;
  margin-top: 13px;

  .image-content {
    width: 25%;
    padding-left: 4%;

    Button {
      margin-top: 37px;
    }

    p {
      margin: 0;
    }

    p:nth-child(1) {
      font-weight: 400;
      font-size: 33px;
      margin-top: 30%;
    }

    p:nth-child(2) {
      font-weight: 600;
      font-size: 33px;
    }

    p:nth-child(4) {
      padding-top: 22px;
      color: #041E3F;
      font-weight: 600;
      font-size: 14px;
      // margin-bottom: 170px;
    }
  }
}

.cards {
  margin-top: 10px;

  .popular-exam {
    background: #041D3C;

    p {
      margin: 0px;
    }

    p:nth-child(1) {
      text-align: center;
      font-weight: 600;
      font-size: 28px;
      color: #FFFFFF;
    }

    p:nth-child(2) {
      padding-top: 18px;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
    }
  }
}

.popular-exam {
  background: #041D3C;

  p {
    margin: 0px;
  }

  p:nth-child(1) {
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    color: #FFFFFF;
  }

  p:nth-child(2) {
    padding-bottom: 42px;
    padding-top: 18px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
  }

  .exam-cards {
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    background-color: #FFFFFF;
    width: 15%;
    font-weight: 600;
    font-size: 14.1887px;
    height: 63px;

    span:nth-child(1) {
      margin-right: 16px;
    }
  }
}

.card-footer {
  text-align: center;
  margin-top: 40px;
  padding-bottom: 31px;
  a {
    color: #F9F9F9;
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
  }
}

.popular-test-series {
  background-color: #FFFFFF;
  border-radius: 5px;
  width: 21%;
  padding: 27px;

  .round {
    border-radius: 30px;
    background: #D9D9D9;
    height: 44px;
    width: 44px;
  }

  .header {
    font-weight: 600;
    font-size: 14px;
    color: #021F55;
  }

  span {
    color: #021F55;
  }

  .ant-divider-horizontal {
    margin: 12px 0px;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    color: #37434E;
  }

  Button {
    background: #07BD81;
    position: relative;
    bottom: 0px;
  }

  .ant-btn>span {
    color: #FFFFFF;
  }
}

.test-series-footer {
  text-align: center;
  margin-top: 30.57px;
  margin-bottom: 68px;

  a {
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
  }
}

.banner {
  padding: 124px 0px 90px 91px;
  background: url(../assets/images/bannerImage.svg);
  background-repeat: no-repeat;
  background-size: 98%;
  margin-left: 37px;

  // height: 100vh;
  .banner-content {
    width: 40%;

    p:nth-child(1) {
      font-weight: 600;
      font-size: 38px;
      color: #F9F9F9;
    }
  }

  .learn {
    p {
      font-weight: 600 !important;
      font-size: 18px !important;
      color: #F9F9F9 !important;
    }
  }

  .ant-btn {
    color: #FFFFFF;
    width: 170px;
    border: 1px solid #F9F9F9;
    border-radius: 4px;
    background: #2D81F7;
    height: 44px;
  }

  .play-store {
    margin-left: 30px;
    display: flex;
    justify-content: space-evenly;
    width: 174px;
    height: 44px;
    border-radius: 4px;
    background: #FFFFFF;
    padding: 10px;

    span {
      padding-top: 1px;
      font-weight: 600;
      font-size: 14px;
      color: #2D81F7;
    }
  }
}

.banner-2 {
  display: flex;
  justify-content: space-around;

  .why-centum {
    margin-top: 84px;
    width: 50%;
    background: url(../assets/images/Vector.svg);
    background-repeat: no-repeat;

    .why-centum-div {
      p:nth-child(1) {
        font-weight: 400;
        font-size: 38px;
        color: #1B2228;
        padding-right: 15px
      }

      p:nth-child(2) {
        font-weight: 600;
        font-size: 38px;
        color: #1B2228;
      }
    }

    .why-centum-content {
      font-weight: 500;
      font-size: 18px;
      padding-left: 91px;
    }
  }

  .small-cards {
    display: flex;

    // justify-content: space-evenly;
    .cards {
      width: 280px;
      // height: 250px;
      border-radius: 10px;
      background: #FFFFFF;

      .ant-image {
        margin: 21px 0px 32px 13px;
      }

      .header {
        padding-left: 13px;
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 8px;
      }

      .content {
        padding: 0px 15px 0px 13px;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.footer {
  margin-top: 23px;
  background: #021F55;

  .inner-footer {
    display: flex;

    .inner-footer-1 {
      width: 25%;

      .app-logo {
        padding: 47px 0px 53px 85px;
      }

      .follow {
        margin: 0;
        padding: 53px 0px 0px 85px;
      }

      .logo-images {
        padding: 10px 0px 42px 75px;

        .ant-image {
          margin-left: 10px;
        }
      }

      div:nth-child(2) {
        p {
          font-weight: 500;
          font-size: 16px;
          color: #F9F9F9;
        }
      }

      .play-store {
        margin-left: 82px;
        display: flex;
        justify-content: space-evenly;
        width: 174px;
        height: 44px;
        border-radius: 4px;
        border: 1px solid #F9F9F9;
        background: #021F55;
        padding: 10px;

        span {
          padding-top: 1px;
          font-weight: 600;
          font-size: 14px;
          color: #2D81F7;
        }
      }
    }

    .inner-footer-2,
    .inner-footer-3,
    .inner-footer-4 {
      padding-top: 91px;

      ul {
        font-weight: 500;
        font-size: 16px;
        list-style: none;
        color: #F9F9F9;
      }
    }
  }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .nav {
    .nav-content {
      display: flex;
      justify-content: space-between;
      width: 52%;
    }
  }

  .landing-page {
    .image-content {
      p:nth-child(1) {
        font-size: 13px;
        margin-top: 18%;
      }

      p:nth-child(2) {
        font-size: 13px;
      }

      p:nth-child(4) {
        padding-top: 18px;
        font-size: 8px;
      }

      Button {
        margin-top: 15px
      }
    }
  }

  .popular-exam {
    p:nth-child(2) {
      font-size: 13px;
    }

    .exam-cards {
      font-size: 10px;
      .ant-image{
        .ant-image-img{
          max-height: 30px !important;
          max-width: 30px;
        }
      }
    }
  }

  .popular-test-series {
    display: flex;
    flex-wrap: wrap;
    width: 46%;
    margin: 2% 2%;
  }

  .overall-populat-test-series {
    display: flex;
    flex-wrap: wrap;
  }

  .banner {
    margin: 0% 5%;
    width: 90%;
    padding: 45px 0px 90px 30px;

    .banner-content {
      p:nth-child(1) {
        font-size: 18px;
      }

      .ant-btn {
        height: 35px;
      }

    }

    .learn {
      p {
        font-size: 9px !important;
      }
    }

    .play-store {
      padding: 4px;
      height: 35px;

      span {
        padding-top: 9px;
        font-size: 5px;
        ;
      }
    }
  }

  .banner-2 {
    display: block;

    .why-centum {
      margin-top: 10px;
      width: 100%;
    }
  }

  .overall-cards {
    margin: 0% 2%;
    width: 96%;
  }

  .footer {
    .inner-footer {
      flex-wrap: wrap;
      .inner-footer-1{
        .app-logo{
          padding: 0px 0px 0px 55px;
        }
      }

      .inner-footer-1,.inner-footer-2, .inner-footer-3 , .inner-footer-4{
        width: 50%;
        padding-top: 50px;
      }

      // .inner-footer-2 {
      //   width: 50%;
      // }

      // .inner-footer-3 {
      //   width: 50%;
      // }

      // .inner-footer-4 {
      //   width: 50%;
      // }
    }
  }
}


// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

  // .popular-exam p:nth-child(2){
  //   font-size: 15px;
  // }
  .landing-page .image-content p:nth-child(4) {
    font-size: 10px;
  }

  .landing-page .image-content p:nth-child(1) {
    font-size: 20px;
    margin-top: 18%;
  }

  .landing-page .image-content p:nth-child(2) {
    font-size: 17px;
  }

  .landing-page .image-content Button {
    margin-top: 20px;
  }

  .popular-test-series Button {
    right: 17px;
  }

  .banner {
    padding: 10px 0px 90px 54px;
  }

  .banner .banner-content p:nth-child(1) {
    font-size: 25px;
    margin-top: 10%;
  }

  .banner .learn p {
    font-size: 13px !important;
  }

  .banner .play-store span {
    font-size: 9px;
    padding-top: 5px;
  }

  .banner-2 .why-centum .why-centum-div p:nth-child(1) {
    font-size: 25px;
  }

  .banner-2 .why-centum .why-centum-div p:nth-child(2) {
    font-size: 25px;
  }

  .banner-2 .why-centum .why-centum-content {
    font-size: 12px;
    padding-left: 36px;
  }

  .banner-2 .small-cards .cards .content {
    font-size: 9px;
  }

  .banner-2 .small-cards .cards .header {
    padding-bottom: 0px;
    font-size: 12px
  }

  .banner-2 .why-centum {
    background-size: 376px;
    margin-top: 63px;
    height: 250px;
  }

  .banner-2 .small-cards .cards .ant-image {
    margin: 8px 0px 5px 13px;
  }

  .banner-2 .small-cards {
    padding-right: 45px;
  }

  .banner-2 .small-cards .cards {
    height: 160px;
    width: 160px;
  }

  .card-image {
    .ant-image {
      .ant-image-img {
        height: 52px;
      }
    }
  }

  .why-centum-div {
    padding-top: 45px;
  }

  .footer-last {
    display: flex;
    justify-content:space-between;
     color:#F9F9F9;
    p:nth-child(1) {
      font-size: 10px !important;
    }

    .footer-image {
      padding-right: 45px;
      span {
        font-size: 10px !important;
      }
    }
  }

  .footer {
    .inner-footer {
      .inner-footer-1 {
        .app-logo {
          padding: 47px 0px 15px 25px;
        }

        .logo-images {
          padding: 0px 0px 42px 19px;
        }

        .play-store {
          margin-left: 25px;
        }

        .follow {
          padding: 53px 0px 10px 28px;
        }
      }
    }
  }
}



// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .landing-page {
    .image-content {
      p:nth-child(2) {
        font-size: 25px;
      }

      p:nth-child(1) {
        font-size: 25px;
        margin-top: 20%;
      }

      p:nth-child(4) {
        font-size: 13px;
      }

      Button {
        margin-top: 20px;
      }
    }
  }

  .banner {
    padding: 60px 0px 90px 91px;

    .banner-content {
      p:nth-child(1) {
        font-size: 34px;
      }
    }

    .learn {
      p {
        font-size: 17px !important;
      }
    }
  }

  .banner-2 {
    .small-cards {
      .cards {
        height: 160px;
        width: 217px;

        .header {
          font-size: 12px;
          padding-bottom: 0px;

        }

        .content {
          font-size: 11px;
        }
      }
    }

    .overall-cards {
      margin-left: 40px;
      margin-top: 19px;
    }
  }
}

.card-image {
  .ant-image {
    .ant-image-img {
      height: 44px;
      width: 44px;
    }
  }
}

.banner-2 .small-cards .cards .ant-image {
  margin: 8px 0px 5px 13px;
}



// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

.footer-last{
  padding-bottom: 25px;
  p{
    margin: 0px;
  }
  .footer-content{
    cursor: pointer;
    color: #F9F9F9;
    font-weight: 500;
    font-size: 16px;
    padding-left: 72px;
    span{
      padding-right: 10px;
      border-right: #fff 1px solid;
      padding-left: 10px;
  }
    }
    .footer-image{
      padding-right: 36px;
      font-weight: 500;
      color: #F9F9F9;
      font-size: 12px;
      span{
        padding-left: 6px;
        margin-top: 4px !important;
      }
    }
    .footer-wrapper{
      display: flex;
      justify-content: space-between;
    }
    .footer-divider{
      width: 96%;
      background-color: rgb(255, 255, 255);
      margin: 24px 25px 24px 25px;
    }
  }
@import "./variable.scss";

.ant-layout-sider {
  background-color: $primary-white;
}

.ant-menu-item-selected {
  background-color: $primary-light-blue !important;
  border-radius: 6px !important;

  a {
    display: flex;
    align-items: center;
    color: $primary-white !important;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 12px;

    .nav-text {
      width: 70%;
      padding: 4px 0px 0px 12px;
    }
  }
}

.ant-menu-item {
  margin: 0px 6px 0px 4px !important;

  a {
    display: flex;
    align-items: center;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 12px;

    .nav-text {
      font-size: 13px !important;
      width: 70%;
      padding: 4px 0px 0px 12px;
    }
  }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: auto !important;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
  padding: 4px 26px !important;
}

.ant-layout-sider-trigger {
  background: $primary-white !important;

  span {
    color: $primary-black;
  }
}

.logo {
  // margin: 14px 0px;
  text-align: center;
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .logo-without-text {
    margin: 5px 0px;
  }
}

.home-container {
  // padding: 20px 25px;
  padding: 24px;

  // overflow-y: scroll;
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0px;
    }

    .view-all {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #021F55;
      ;
      padding: 0px 20px 0px 0px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .random-header {
    background-color: $primary-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 26px;
    margin-bottom: 16px;
    border-radius: 10px;

    p {
      margin: 0px;
    }

    .user-container {
      display: flex;

      .userProfile {
        // height: 100%;
        // width: 28px;
        text-align: center;

        .user-profile-icon{
          width: 50px !important;
          border-radius: 50px !important;
          height: 50px !important;
        }
        .firstName {
          width: 50px;
          height: 50px;
          background-color: #DBE4EF;
          border-radius: 50%;
          border: 2px solid #DBE4EF;
          color: #37434E;
          font-weight: 600;
          font-size: 20px;
          line-height: 44px;
          padding: 2px;
        }
      }

      .user-details {
        margin: 0px 0px 0px 9px;

        .user-name {
          font-weight: 600;
          font-size: 18px;
          line-height: 17px;
          color: $primary-dark-blue;
          padding: 6px 0px;
          text-transform: capitalize;

        }

        .tag {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: $secondary-grey;
        }
      }
    }

    .subscription-container {
      display: none;

      .subscription-details {
        .subscription-tag {
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          color: $primary-grey;
          padding: 3px 0px;
        }

        .subscription-amount {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $primary-black;
        }
      }

      .primary-submit-button {
        margin: 0px 0px 0px 38px;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: $primary-white;
        background: $primary-light-blue !important;
        border-radius: 2px;
        border: 1px solid $primary-light-blue;

        &:hover {
          background: #196DE3;
        }
      }
    }
  }

  .container-wrapper {
    overflow-y: scroll;
    height: calc(100vh - 88px - 130px);
  }

  .take-challenge {
    margin: 0px 0px 19px 0px;

    .header-text {
      padding: 0px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #021F55;
    }
  }

  .new-exams {
    margin: 0px 0px 19px 0px;

    .header-text {
      padding: 6px 0px 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #021F55;
    }
  }

  .test-series {
    margin: 0px 0px 19px 0px;

    .test-series-card {
      // width: 324px;
      background-color: $primary-white;
      padding: 20px;
      border-radius: 10px;
      margin: 0px 18px 0px 0px;
      border: 1px solid $border-grey-color;

      p {
        margin: 0px;
      }

      .test-series-header {
        color: $primary-light-blue;
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
      }

      .test-name {
        color: $primary-black;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        padding: 6px 0px;
        text-transform: capitalize;
      }

      .test-details {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .test-content {
          color: $primary-grey;
          font-size: 11px;
          font-weight: 500;
          line-height: 14px;
          flex-grow: 2;

          .total-topic {
            padding: 0px 5px;
            border-right: 1px solid $secondary-grey;
            border-left: 1px solid $secondary-grey;
          }

          .total-test {
            padding: 0px 5px 0px 0px;
          }

          .total-hours {
            padding: 0px 5px;
          }
        }

        .test-series-button {
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          color: $primary-white;
          background: $primary-green;
          border-radius: 2px;
          border: 1px solid $primary-green;
        }
      }
    }
  }

  .purchased-test-series {
    margin: 0px 0px 19px 0px;
  }

  .home-test {
    margin: 0px 0px 19px 0px;

    .header-text {
      padding: 0px;
      padding-top: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #021F55;
    }
  }
}

.header-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: $primary-dark-blue;
  margin: 0px 0px 9px 0px;
}

// test series scss
// .test-series-card {
//   // width: 324px;
//   background-color: $primary-white;
//   padding: 20px;
//   border-radius: 10px;
//   margin: 0px 18px 0px 0px;
//   border: 1px solid $border-grey-color;

//   p {
//     margin: 0px;
//   }
//   .test-series-header {
//     color: $primary-light-blue;
//     font-size: 8px;
//     font-weight: 500;
//     line-height: 10px;
//   }
//   .test-name {
//     color: $primary-black;
//     font-size: 12px;
//     font-weight: 500;
//     line-height: 15px;
//     padding: 6px 0px;
//   }
//   .test-details {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     .test-content {
//       color: $primary-grey;
//       font-size: 11px;
//       font-weight: 500;
//       line-height: 14px;
//       flex-grow: 2;
//       .total-topic {
//         padding: 0px 5px;
//         border-right: 1px solid $secondary-grey;
//         border-left: 1px solid $secondary-grey;
//       }
//       .total-test {
//         padding: 0px 5px 0px 0px;
//       }
//       .total-hours {
//         padding: 0px 5px;
//       }
//     }

//     .test-series-button {
//       font-weight: 500;
//       font-size: 10px;
//       line-height: 12px;
//       color: $primary-white;
//       background: $primary-green;
//       border-radius: 2px;
//       border: 1px solid $primary-green;
//     }
//   }
// }

// single-test
.single-test-card {
  // width: 324px;
  background-color: $primary-white;
  padding: 14px;
  border-radius: 10px;
  margin: 0px 18px 0px 0px;
  border: 1px solid $border-grey-color;

  p {
    margin: 0px;
  }

  .single-test-free-header {
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    color: $primary-green;
    padding-bottom: 6px;
  }

  .single-test-paid-header {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: $primary-red;
  }

  .test-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: $primary-black;
    padding: 6px 0px;
    text-transform: capitalize;

  }

  .test-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .test-content {
      .total-question {
        color: $primary-grey;
        font-weight: 500;
        font-size: 13px;
        line-height: 12px;
        padding: 0px 5px;
      }

      .total-mins {
        color: $primary-grey;
        font-weight: 500;
        font-size: 13px;
        line-height: 12px;
        border-left: 1px solid $secondary-grey;
        padding: 0px 5px;
        border-right: 1px solid $secondary-grey;
      }
    }

    .test-languages {
      color: $primary-dark-blue;
      font-size: 13px;
      font-weight: 500;
      line-height: 12px;
      word-break: break-all;
      white-space: normal;
      // flex-grow: 2;
      padding: 0px 5px;
    }

    .single-test-free-button {
      font-weight: 500;
      font-size: 13px;
      line-height: 12px;
      color: $primary-white;
      background: $primary-green;
      border-radius: 2px;
      border: 1px solid $primary-green;
    }

    .single-test-paid-button {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: $primary-white;
      background: $primary-red;
      border-radius: 2px;
      border: 1px solid $primary-red;
    }
  }
}

// .single-test-card:hover{
//   border: 1px solid #B8C3C9;
//   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
//   border-radius: 5px;
// }
.new-exam-container {
  cursor: pointer;
  background: $primary-white;
  // width: 222px;
  padding: 10px;
  margin: 0px 18px 12px 0px;
  border-radius: 8px;
  display: flex;
  // justify-content: center;
  align-items: center;
  border: 1px solid $border-grey-color;

  .exam-image {
    .ant-image {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-image-img {
        height: 100%;
      }
    }
  }

  p {
    margin: 0px;
  }

  .exam-text {
    .exam-name {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $primary-dark-blue;
      margin: 0px 0px 0px 16px;
      // width: 55%;
      text-transform: capitalize;
    }
  }
}

.new-exam-container:hover {
  border: 1px solid #B8C3C9;
  box-shadow: 0px 2px 2px rgba(159, 159, 159, 0.15);
  border-radius: 5px;
}

// card-scroll
.card-scroll {
  .single-card {
    // width: 345px;
  }
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 100%;

  .left-header {
    .hamburger {
      display: none;
    }
  }

  .right-header {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0px;
  }

  // .user-logo {
  //   width: 28px;
  // }
  .userProfile {
    // height: 100%;
    // width: 28px;
    text-align: center;
    cursor: pointer;
    .user-profile-icon{
      width: 50px !important;
      border-radius: 50px !important;
      height: 50px !important;
    }
    .firstName {
      width: 28px;
      height: 28px;
      background-color: #DBE4EF;
      border-radius: 50%;
      border: 2px solid #DBE4EF;
      color: #37434E;
      font-weight: 600;
      font-size: 15px;
    }
  }

  .ant-badge {
    margin-right: 7px;

    .ant-badge-count {
      background-color: $primary-red;
      color: $primary-white;
    }
  }

  .cart-icon {
    cursor: pointer;
    padding: 5px;

    &:hover {
      background-color: #e3e6e8;
      border-radius: 50%;
    }

  }

  .ant-divider {
    height: 25px;
    margin-right: 16px;
  }
}

.test-series-header-container {
  padding: 33px 29px;
  background: $primary-white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0px;
  }

  .series-details-container {
    .series-details {
      .series-header {
        font-weight: 500;
        font-size: 9px;
        line-height: 11px;
        color: $secondary-white;
        background: $primary-light-blue;
        padding: 4px 10px;
      }

      .series-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $primary-black;
        padding: 10px 0px 14px 0px;
      }
    }

    .series-total {
      color: $primary-black;
      font-weight: 500;

      .test-total-languages-details {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: $primary-black;

        .ant-image {
          margin: 0px 5px 0px 0px;
        }

        .total-test {
          padding: 0px 6px 0px 0px;
        }

        .total-ques {
          padding: 0px 6px;
          border-right: 1px solid $secondary-grey;
          border-left: 1px solid $secondary-grey;
        }

        .total-hours {
          padding: 0px 0px 0px 6px;
        }
      }

      .total {
        .total-text {
          font-size: 12px;
          line-height: 15px;
          padding: 0px 10px 0px 7px;
          border-right: 1px solid $secondary-grey;
        }
      }

      .languages {
        padding: 5px;

        .total-languages {
          padding: 0px 10px 0px 10px;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: $primary-grey;
        }
      }
    }
  }

  .series-purchase {
    .primary-submit-button {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: $primary-white;
      background: $primary-light-blue !important;
      border-radius: 2px;
      border: 1px solid $primary-light-blue;
      padding: 4px 20px;

      &:hover {
        background: #196DE3;
      }
    }
  }
}

.test-series-container {

  // padding: 4px 0px 4px 35px;
  .breadcrumb-details {
    margin: 0px 0px 15px 0px;
  }

  .test-series-header {
    margin: 0px 0px 19px 0px;
  }

  .test-series-list {
    .list-header {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $primary-dark-blue;
      margin: 0px 0px 19px 0px;
    }

    .list-container {
      // display: flex;
      // width: 70%;
    }
  }
}

.test-bundle-width {
  width: 100%;
}

.test-series-list-container {
  // width: 500px;
  background: #ffffff;
  border-radius: 5px;
  padding: 12px 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-left: 5px solid $primary-green;
  margin: 4px 20px 4px 0px;

  p {
    margin: 0px;
  }

  .list-details {
    .test-status-free {
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
      color: $primary-green;
    }

    .test-status-paid {
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
      color: $primary-light-blue;
      border: 1px solid $primary-light-blue;
    }

    .test-status-result {
      font-weight: 500;
      font-size: 9px;
      line-height: 11px;
      color: $primary-green;
    }

    .test-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $primary-dark-blue;
      text-transform: capitalize;

    }

    .test-total-languages-details {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $primary-black;

      .total-text {
        padding: 0px 10px 0px 0px;
        border-right: 1px solid $third-level-grey;
      }

      .mark-text {
        padding: 0px 10px 0px 10px;
        border-right: 1px solid $third-level-grey;
      }

      .languages-text {
        padding: 0px 0px 0px 10px;
      }
    }

    .languages {
      padding: 0px 0px 0px 0px;

      .total-languages {
        padding: 0px 10px 0px 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: $primary-grey;
      }
    }
  }

  .list-button {
    .primary-green-submit-button {
      background: $primary-green;
      border-radius: 2px;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: $primary-white;
      border: 1px solid $primary-green;
      // padding: 6px 10px;
    }

    .primary-red-submit-button {
      background: $primary-white;
      border-radius: 2px;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: $primary-light-blue;
      border: 1px solid $primary-light-blue;
      // padding: 6px 10px;
    }

    // .primary-result-submit-button {
    //   background: $primary-white;
    //   border-radius: 2px;
    //   font-weight: 500;
    //   font-size: 12px;
    //   line-height: 15px;
    //   color: $primary-dark-blue;
    //   border: 1px solid $fourth-level-grey;
    //   padding: 6px 10px;
    // }
  }
}

.border-free-result {
  border-left: 5px solid $primary-green;
  // opacity: 0.3;
}

.border-paid {
  border-left: 5px solid $primary-red;
  // opacity: 0.3;
}

.border-empty {
  border-left: none;
  // opacity: 0.3;
}

.ant-breadcrumb li {
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: $secondary-grey;
  cursor: pointer;
  text-transform: capitalize;
}

.ant-breadcrumb li a {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $secondary-grey;
}

.ant-breadcrumb li .ant-breadcrumb-separator {
  // font-size: 14px;
  color: $third-level-grey;
}

.ant-breadcrumb-separator {
  margin: 0px;

  .ant-image {
    text-align: center;

    img {
      width: 80%;
    }
  }
}

.ant-breadcrumb li:last-child a {
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: $primary-dark-blue;
}

.test-instruction-container {
  padding: 24px;
  // .ant-breadcrumb li a{
  //   color: yellow !important;
  // }
  // .ant-breadcrumb li a:nth-child(2){

  // }
  .breadcrumb-details {
    margin: 0px 0px 15px 0px;
  }

  .test-instruction-header {
    .test-instruction-header-container {
      margin: 0px 0px 14px 0px;
      padding: 20px 40px 20px 16px;
      border-radius: 6px;
      background: $primary-white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0px;
      }

      .instruction-details-container {
        .instruction-details {
          border-radius: 6px;

          .instruction-header {
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            color: $primary-red;
          }

          .exam-title {
            margin-top: 5px;
            font-weight: 600;
            font-size: 10px;
            // color: #2D81F7;
            // color: #021F55;
            color: #1B2228;

            text-transform: capitalize;

            .full-test-title {
              // margin-right: 8px;
              color: #D06200 !important;
              font-weight: 600;
              font-size: 10px;
              // background: rgba(252, 204, 186, 0.1);
              // border: 1px solid #E1C5BB;
              border-radius: 2px;
              padding: 4px 0px;
              text-transform: capitalize;
            }

            .subject-title {
              color: #039464 !important;
              // margin-right: 8px;
              font-weight: 600;
              font-size: 10px;
              // background: rgba(7, 189, 129, 0.05);
              // border: 1px solid rgba(8, 151, 104, 0.2);
              border-radius: 2px;
              padding: 4px 0px;
              text-transform: capitalize;

            }

            .chapter-title {
              // margin-right: 8px;
              color: #5D0AAA;
              font-weight: 600;
              font-size: 10px;
              // background: rgba(155, 81, 224, 0.05);
              // border: 1px solid rgba(155, 81, 224, 0.4);
              border-radius: 2px;
              padding: 4px 0px;
              text-transform: capitalize;

            }
          }

          .instruction-name {
            font-weight: 600;
            line-height: 19px;
            font-size: 14px;
            color: #021F55;
            padding: 10px 0px 14px 0px;
            text-transform: capitalize;

          }
        }

        .instruction-total {
          color: $primary-black;
          font-weight: 500;

          .total {
            .total-question {
              font-size: 13px;
              font-weight: 500;
              line-height: 15px;
              padding: 0px 10px 0px 7px;
              border-right: 1px solid $secondary-grey;
              margin-right: 10px;
            }

            .total-marks {
              font-size: 13px !important;
              font-weight: 500 !important;
              line-height: 15px !important;
              padding: 0px 10px 0px 7px;
              border-right: 1px solid $secondary-grey;
              margin-right: 10px;
            }

            .total-mins {
              font-size: 13px !important;
              line-height: 15px !important;
              font-weight: 500 !important;
              padding: 0px 10px 0px 7px;
              // border-right: 1px solid $secondary-grey;
            }
          }

          .languages {
            padding: 12px 0px 0px 0px;

            .total-languages {
              padding: 0px 10px 0px 10px;
              font-size: 13px;
              line-height: 12px;
            }
          }
        }
      }

      .instruction-purchase {
        .testLevel {
          display: inline;
        }

        .ant-select {
          margin: 0px 10px 0px 0px;
        }

        .primary-submit-button {
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          color: $primary-white;
          background: $primary-light-blue !important;
          border-radius: 2px;
          border: 1px solid $primary-light-blue;
          padding: 4px 20px;

          &:hover {
            background: #196DE3 !important;
          }
        }
      }
    }
  }

  .test-instruction-Details {
    padding: 31px;
    background: $primary-white;
    border-radius: 6px;

    .instruction-data {
      font-weight: 400;
      font-size: 13px;
      line-height: 23px;
      color: #37434E;

      ol {
        padding: 21px 16px;
      }
    }

    .header-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $primary-black;
      text-align: left;
      padding: 0px
    }

    .test-instruction-content {
      padding: 10px 0px;

      p {
        margin: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 23px;
        color: $primary-grey;
        padding: 1px 0px;
      }
    }
  }
}

// @media screen and(min-width:1024) {
.test-page-container {
  display: flex;
  background: $primary-background-color;
  height: 100vh;

  .mobile-btn-toggle {
    display: none;
  }

  .question-container {
    width: 70%;
    margin: 0px 38px;

    .question-container-body {
      .question-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;

        .timer-content {
          display: flex;
          padding: 0px 20px;
          // .timer-text {
          //   font-weight: 600;
          //   font-size: 16px;
          //   line-height: 20px;
          //   color: $primary-dark-blue;
          //   padding: 4px 16px 2px 16px;
          // }
        }
      }

      .question-details {

        // background: $primary-white;
        .question-details-header {
          background: $primary-white;

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;

          .question-number {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $primary-dark-blue;
          }

          .question-flags {
            .status {
              color: $secondary-white;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;

              // margin: 0px 20px;
              .wrong-status {
                background: $primary-red;
                padding: 5px 10px;
                border-radius: 3px;
                margin: 0px 8px;
              }

              .correct-status {
                background: $primary-green;
                padding: 5px 10px;
                border-radius: 3px;
                margin: 0px 8px;
              }
            }

            .bookmark {
              padding: 10px;
              cursor: pointer;

              span {
                padding: 5px 0px 0px 10px;
              }
            }

            .report {
              padding: 10px;
              cursor: pointer;

              .report-text {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: $primary-black;
                // padding:;
              }
            }
          }
        }

        .question-details-content {}
      }
    }

    .question-details-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 15vh;

      .outline-test-button {
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: $primary-light-blue;
        border: 1px solid $primary-light-blue;
        background: $primary-white;
        margin: 10px;
        border-radius: 4px;
      }

      .mark-review-button {
        color: #ffff;
        background-color: #FFA443;
        border: #FFA443;
        margin: 10px;
        border-radius: 4px;
      }

      .filled-test-button {
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: $primary-white;
        background-color: #07bd81 !important;
        border: #07bd81 !important;
        margin: 10px;
        border-radius: 4px;

      }
    }
  }

  .question-buttons {
    width: 30%;
    background: #F5F6F8;

    .question-button-content {
      height: calc(100vh - 15vh) !important;
      padding: 24px 19px;
      background: $primary-white;
      height: 602px;
      margin: 0px 0px 4px 0px;
      height: 85vh;

      .test-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: $primary-black;
        text-transform: capitalize;

      }

      .question-pad-details {
        p {
          margin: 0px;
        }

        .question-header {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: $primary-black;
        }

        .total-question {
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: $secondary-grey;
          padding: 5px 0px;
        }

        .marker-details {
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
          color: $secondary-grey;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .marker-data {
            padding: 0px 20px 0px 0px;
            font-size: 13px;

            .ant-badge-status-dot {
              border-radius: 2px;
              width: 8px;
              height: 8px;
            }

            .ant-badge-status-success {
              background-color: $primary-dark-blue;
              border: 1px solid $fourth-level-grey;
            }

            .ant-badge-status-warning {
              background-color: $primary-orange;
            }

            .ant-badge-status-default {
              border: 1px solid $fourth-level-grey;
              background-color: $primary-white;
            }
          }
        }

        .question-button-status {
          max-height: calc(100vh - 15vh - 200px);
          overflow: scroll;

          .ant-col {
            margin: 10px 0px 10px 0px;
          }

          .current-question {
            border: 1px solid $primary-green !important;
            background-color: $primary-green !important;
            color: $primary-white !important;
          }

          .answered-question {
            border-radius: 3px;
            border: 1px solid $border-grey-color;
            background-color: $primary-dark-blue;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $primary-white;
            width: 30px;
            height: 30px;
            padding: 0px;

            .badge {
              position: absolute;
              bottom: -6px;
              right: -6px;
            }
          }

          // .answer-marked-question {
          //   border-radius: 3px;
          //   border: 1px solid $border-grey-color;
          //   background-color: $primary-dark-blue;
          //   font-weight: 500;
          //   font-size: 12px;
          //   line-height: 15px;
          //   color: $primary-white;
          //   width: 30px;
          //   height: 30px;
          //   padding: 0px;
          //   .badge {
          //     position: absolute;
          //     bottom: -6px;
          //     right: -6px;
          //   }
          // }
          // .unanswer-marked-question {
          //   background-color: $primary-white;
          //   border-radius: 3px;
          //   border: 1px solid $border-grey-color;
          //   font-weight: 500;
          //   font-size: 12px;
          //   line-height: 15px;
          //   color: $primary-dark-blue;
          //   width: 30px;
          //   height: 30px;
          //   padding: 0px;
          //   .badge {
          //     position: absolute;
          //     bottom: -6px;
          //     right: -6px;
          //   }
          // }
          .not-answered-question {
            background-color: $primary-white;
            border-radius: 3px;
            border: 1px solid $border-grey-color;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $primary-dark-blue;
            width: 30px;
            height: 30px;
            padding: 0px;

            .badge {
              position: absolute;
              bottom: -6px;
              right: -6px;
            }
          }
        }
      }
    }

    .question-panel-footer-button {
      background: $primary-white;
      // padding: 0px 22px 24px 21px;
      padding: 10spx;
      height: 15vh;
      display: flex;
      align-items: center;
      justify-content: center;

      // flex-direction: column;
      .footer-link-page {
        display: none;
        // display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: $primary-light-blue;
        text-decoration: underline;
        padding: 0px 25px;
      }

      .footer-submit-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 25px;

        .end-test-pause-button {
          // width: 30%;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $primary-light-blue;
          background-color: $primary-white;
          border: 1px solid $primary-light-blue;
          border-radius: 4px;
        }

        .end-test-submit-button {
          // width: 314px;
          // width: 30%;

          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $primary-white;
          background-color: $primary-light-blue;
          border: 1px solid $primary-light-blue;
          border-radius: 4px;
        }

        .time-over-button {
          background-color: #07bd81 !important;
          border: #07bd81 !important;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $primary-white;
          border: 1px solid $primary-light-blue;
          border-radius: 4px;
          // width: 30%;
        }
      }
    }
  }
}

// }

.question-answer-component {
  background: $primary-white;
  padding: 20px;
  height: calc(100vh - 90px - 20vh) !important;
  overflow: scroll;

  p {
    margin: 0px;
  }

  .question-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $primary-black;

    .Question_inline {

      table,
      tr,
      th,
      td {
        border: 1px solid black;
        padding: 4px;
        margin: 5px;
      }

      p {
        display: block;
      }

      img {
        max-width: 400px;
        max-height: 400px;
      }
    }
  }

  .comprehension-text {
    padding: 10px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $primary-black;

    .Question_inline {

      table,
      tr,
      th,
      td {
        border: 1px solid black;
        padding: 4px;
        margin: 5px;

      }

      p {
        display: block;
      }

      img {
        max-width: 400px;
        max-height: 400px;
      }
    }
  }

  .answer-details {
    padding: 14px 0px;

   .ant-row {
      display: block !important;
    
    }

    .option {
      padding: 10px 0px;

      .ant-radio-wrapper {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $primary-grey;
      }
    }
  }

 

  .ant-image {
    width: 50%;
  }
}

.ant-drawer-content-wrapper {
  width: 35% !important;
}

@media screen and (max-width:640px) {
  .ant-drawer-content-wrapper {
    width: 70% !important;
  }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ant-statistic-content {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $primary-dark-blue;
  padding: 2px 5px;
}

.test-attempted-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-white;
  width: 70%;
  padding: 20px;
  margin: 0px 0px 10px 0px;

  p {
    margin: 0px;
  }

  .content-layout {
    .status-text {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $primary-green;
    }

    .test-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $primary-dark-blue;
      padding: 8px 0px;
      text-transform: capitalize;

    }

    .test-details {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $primary-grey;

      .total-question {
        padding: 0px 8px 0px 0px;
      }

      .total-mark-text {
        border-right: 1px solid $third-level-grey;
        border-left: 1px solid $third-level-grey;
        padding: 0px 8px;
      }

      .completed-date {
        padding: 0px 8px;
      }
    }
  }

  .button-layout {
    .primary-green-submit-button {
      background: $primary-green;
      border-radius: 2px;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: $primary-white;
      border: 1px solid $primary-green;
      // padding: 6px 10px;
      margin: 0px 5px;
    }

    .outlined-button {
      background: $primary-white;
      border-radius: 2px;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: $primary-light-blue;
      border: 1px solid $primary-light-blue;
      // padding: 6px 10px;
      margin: 0px 5px;
    }
  }
}

.recent-test-attempted-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-white;
  width: 50%;
  padding: 20px;
  margin: 0px 20px 0px 0px;

  p {
    margin: 0px;
  }

  .content-layout {
    .status-text {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $primary-green;
    }

    .test-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $primary-dark-blue;
      padding: 8px 0px;
      text-transform: capitalize;

    }

    .test-details {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $primary-grey;

      .total-question {
        padding: 0px 8px 0px 0px;
      }

      .total-mark-text {
        border-right: 1px solid $third-level-grey;
        border-left: 1px solid $third-level-grey;
        padding: 0px 8px;
      }

      .completed-date {
        padding: 0px 8px;
      }
    }
  }

  .button-layout {
    .primary-green-submit-button {
      background: $primary-green;
      border-radius: 2px;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: $primary-white;
      border: 1px solid $primary-green;
      // padding: 6px 10px;
      margin: 0px 5px;
    }

    .outlined-button {
      background: $primary-white;
      border-radius: 2px;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: $primary-light-blue;
      border: 1px solid $primary-light-blue;
      // padding: 6px 10px;
      margin: 0px 5px;
    }
  }
}

.attempted-test-container {
  padding: 24px 38px;

  .header-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $primary-dark-blue;
  }

  .recent-test-bundle-wrapper {
    margin: 20px 0px 20px 0px;

    .recent-header {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $primary-dark-blue;
    }

    .test-card-list {
      display: flex;
    }
  }

  .attempted-test-list-wrapper {
    .recent-header {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: flex-end;
      color: $primary-dark-blue;
    }

    .test-card-list {}
  }
}

.question-buttons {}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  display: none;
}

.modal-container {
  p {
    margin: 0px;
  }

  .modal-header {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $primary-black;
    padding: 8px;
  }

  .modal-sub-header {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $primary-grey;
  }

  .marker-details {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $secondary-grey;
    text-align: center;
    padding: 10px 20px 0px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .marker-data {
      padding: 0px 20px 0px 0px;

      .ant-badge-status-dot {
        border-radius: 2px;
        width: 8px;
        height: 8px;
      }

      .ant-badge-status-success {
        background-color: $primary-dark-blue;
        border: 1px solid $fourth-level-grey;
      }

      .ant-badge-status-warning {
        background-color: $primary-orange;
      }

      .ant-badge-status-default {
        border: 1px solid $fourth-level-grey;
        background-color: $primary-white;
      }
    }
  }
}

.ant-modal-footer {
  .ant-btn-default {
    background: $border-grey-color !important;
    border-radius: 3px !important;
    border-color: $border-grey-color;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $primary-grey;
  }

  .ant-btn-primary {
    // padding: 7px 10px !important;
    background: $primary-green !important;
    border-radius: 3px !important;
    border-color: $primary-green;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $primary-white;
    margin: 0px 15px 0px 30px !important;
  }
}

.loading-modal {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-body {
      text-align: center;

      .anticon svg {
        // font-size:30px
      }

      .ant-spin-text {
        color: $primary-light-blue;
        font-size: 16px;
        font-weight: 900;
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.noData {
  color: $primary-black;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  padding: 10px;
}

.ant-spin-dot {
  width: 35px;
  height: 35px;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.logout-container {
  display: flex;
  align-items: center;
  margin: auto;
  // margin: 12px 0px 0px 25px;
  // padding: 0px 0px 0px 24px;
  // margin: 0px 6px 0px 4px;
  cursor: pointer;

  .nav-text {
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 12px;
    padding: 0px 0px 0px 12px;
  }
}

.test-bundle-page-container {
  padding: 4px 22px;

  p {
    margin: 0px;
  }

  .main-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $primary-dark-blue;
    margin: 0px;
  }

  .recent-test-series {
    .header-container {
      display: flex;
      justify-content: space-between;
      padding: 16px 0px;

      .header-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $primary-dark-blue;
      }

      .viewAll {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-decoration: underline;
        color: $primary-light-blue;
      }
    }

    .recent-card-container {
      margin: 0px 0px 11px 0px;

      .card {
        width: 324px;
        background-color: $primary-white;
        padding: 21px 17px;
        border-radius: 10px;

        .ant-slider-rail {
          background-color: $secondary-light-blue;
        }

        .ant-slider-track {
          background-color: $primary-light-blue;
        }

        .ant-slider-handle {
          display: none;
        }

        .card-header {
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          color: $primary-light-blue;
        }

        .test-series-name {
          padding: 7px 0px 0px 0px;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $primary-black;
        }

        .button-container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .test-content {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $primary-grey;
          }

          .test-series-button {
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            color: $primary-white;
            background: $primary-green;
            border-radius: 2px;
            border: 1px solid $primary-green;
          }
        }
      }
    }
  }

  .test-series-banner {
    p {
      margin: 0px;
    }

    .banner-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 22px 24px;
      background-image: linear-gradient(271.2deg, #2d81f7 17.89%, #00134a 100%);
      border-radius: 10px;

      // margin: 11px 0px 28px 0px;
      .image-details {
        flex-grow: 1;

        .exam-details {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $primary-white;
          padding: 16px 0px;
        }
      }

      .subscription-details {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-grow: 1;

        .amount {
          font-weight: 600;
          font-size: 22px;
          line-height: 20px;
          color: $primary-white;
        }

        .test-series-button {
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: $primary-white;
          background: $primary-green;
          border-radius: 2px;
          border: 1px solid $primary-green;
        }
      }
    }
  }

  .test-series-categories {
    margin: 23px 0px 0px 0px;

    .categories-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-input-affix-wrapper {
        color: $fourth-level-grey;
        width: 30%;
      }
    }

    .categories-content {
      display: flex;
      padding: 23px 0px;
      gap: 12px;

      .test-series-exam-category {
        // flex-grow: 1;

        .ant-radio-group {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .ant-radio-button-wrapper {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $primary-grey;
          border: none !important;
          padding: 10px;
          //  position: initial;
        }

        .ant-radio-button-checked {
          border: none !important;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          background-color: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          color: $primary-dark-blue;
          background: $secondary-light-blue;
          border: none !important;
        }
      }

      .test-series-container {
        flex-grow: 2;

        .test-series-card {
          // width: 324px;
          background-color: $primary-white;
          padding: 20px;
          border-radius: 10px;
          margin: 0px 0px 8px 0px;
          border: 1px solid $border-grey-color;

          p {
            margin: 0px;
          }

          .test-series-header {
            color: $primary-light-blue;
            font-size: 10px;
            font-weight: 500;
            line-height: 10px;
          }

          .test-name {
            color: $primary-black;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            padding: 6px 0px;
            text-transform: capitalize;

          }

          .test-details {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .test-content {
              color: $primary-grey;
              font-size: 11px;
              font-weight: 500;
              line-height: 14px;
              flex-grow: 2;

              .total-topic {
                padding: 0px 5px;
                border-right: 1px solid $secondary-grey;
                border-left: 1px solid $secondary-grey;
              }

              .total-test {
                padding: 0px 5px 0px 0px;
              }

              .total-hours {
                padding: 0px 5px;
              }
            }

            .test-series-button {
              font-weight: 500;
              font-size: 12px;
              line-height: 12px;
              color: $primary-white;
              background: $primary-green;
              border-radius: 2px;
              border: 1px solid $primary-green;
            }
          }
        }
      }
    }
  }
}

.test-all-container {
  .main-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $primary-dark-blue;
    margin: 0px 0px 8px 0px;
  }

  .test-all-banner {
    margin: 0px 0px 10px 0px;

    p {
      margin: 0px;
    }

    .banner-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 22px 24px;
      background-image: linear-gradient(271.2deg, #2d81f7 17.89%, #00134a 100%);
      border-radius: 10px;

      // margin: 11px 0px 28px 0px;
      .image-details {
        flex-grow: 1;

        .exam-details {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $primary-white;
          padding: 16px 0px;
        }
      }

      .subscription-details {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-grow: 1;

        .amount {
          font-weight: 600;
          font-size: 22px;
          line-height: 20px;
          color: $primary-white;
        }

        .test-series-button {
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: $primary-white;
          background: $primary-green;
          border-radius: 2px;
          border: 1px solid $primary-green;
        }
      }
    }

    .test-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $primary-black;
      margin: 10px 0px;
    }
  }

  .test-all-category {
    .category-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-input-affix-wrapper {
        color: $fourth-level-grey;
        width: 30%;
      }
    }

    .category-content {
      display: flex;
      padding: 23px 0px;
      gap: 12px;

      .exam-category {
        .ant-radio-group {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .ant-radio-button-wrapper {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: $primary-grey;
          border: none !important;
          padding: 10px;
          //  position: initial;
        }

        .ant-radio-button-checked {
          border: none !important;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          background-color: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          color: $primary-dark-blue;
          background: $secondary-light-blue;
          border: none !important;
        }
      }

      .test-all-container {
        flex-grow: 2;
        margin: 0px 0px 19px 0px;

        .test-series-card {
          // width: 324px;
          background-color: $primary-white;
          padding: 20px;
          border-radius: 10px;
          margin: 0px 18px 0px 0px;
          border: 1px solid $border-grey-color;

          p {
            margin: 0px;
          }

          .test-series-header {
            color: $primary-light-blue;
            font-size: 10px;
            font-weight: 500;
            line-height: 10px;
          }

          .test-name {
            color: $primary-black;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            padding: 6px 0px;
            text-transform: capitalize;

          }

          .test-details {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .test-content {
              color: $primary-grey;
              font-size: 11px;
              font-weight: 500;
              line-height: 14px;
              flex-grow: 2;

              .total-topic {
                padding: 0px 5px;
                border-right: 1px solid $secondary-grey;
                border-left: 1px solid $secondary-grey;
              }

              .total-test {
                padding: 0px 5px 0px 0px;
              }

              .total-hours {
                padding: 0px 5px;
              }
            }

            .test-series-button {
              font-weight: 500;
              font-size: 12px;
              line-height: 12px;
              color: $primary-white;
              background: $primary-green;
              border-radius: 2px;
              border: 1px solid $primary-green;
            }
          }
        }
      }
    }
  }
}

.ant-menu-item-selected {
  background-color: #2d81f7 !important;
  border-radius: 6px !important;
}

.availableTest-container {
  // margin: 15px 25px;
  padding: 24px;
  // height: 100%;

  .availableTest-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $primary-white;
    padding: 0px 25px;
    margin: 16px 0px;
    border: 1px solid $border-grey-color;
    border-radius: 10px;

    .header-text {
      font-weight: 600;
      font-size: 22px;
      color: $primary-black;
    }
  }


  .availableTest-content {
    .availableTest-filter-list {
      .ant-tabs-nav-list {
        background: $primary-white;

        .ant-tabs-tab {
          padding: 8px 21px;
          font-weight: 500;
          font-size: 14px;
          color: $secondary-grey;
          margin: 0px;
        }

        .ant-tabs-tab-active {
          color: $primary-light-blue;
        }
      }

      .availableTest-list-data {
        overflow-y: scroll;
        height: calc(100vh - 322px);
      }
    }

    .test-list {
      background: $primary-white;
      padding: 16px 20px;

      @media screen and (max-width:640px) {
        width: 100%;

      }

      @media screen and (min-width:640px) and (max-width:1024px) {
        width: 100%;
      }

      @media screen and (min-width:1024px) {
        width: 65%;
      }

      // width: 65%;
      border: 1px solid $border-grey-color;
      border-radius: 5px;
      margin: 0px 0px 5px 0px;
      cursor: pointer;

      p {
        margin: 0px;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      .test-details {
        .fullTest {
          font-weight: 600;
          font-size: 12px;
          // background-color: $primary-light-pink;
          // border: 1px solid $border-pink-color;
          border-radius: 2px;
          padding: 4px 0px;
          color: $primary-dark-orange;
          text-transform: capitalize;
        }

        .subTest {
          font-weight: 600;
          font-size: 12px;
          // background-color: $primary-green-background;
          // border: 1px solid $primary-green-border;
          border-radius: 2px;
          padding: 4px 0px;
          color: $secondary-green;
          text-transform: capitalize;

        }

        .chapTest {
          font-weight: 600;
          font-size: 12px;
          // background-color: $primary-purple-background;
          // border: 1px solid $primary-purple-border;
          border-radius: 2px;
          padding: 4px 0px;
          color: $primary-purple;
          text-transform: capitalize;

        }

        .examName {
          font-weight: 600;
          font-size: 12px;
          // color: #021F55;
          color: #1B2228;
          // padding: 0px 8px;
          text-transform: capitalize;

        }

        .testName {
          font-weight: 600;
          font-size: 14px;
          color: $primary-dark-blue;
          margin: 6px 0px 0px 0px;
          text-transform: capitalize;
        }
      }

      .test-availabel-details {
        display: flex;
        flex-direction: column;
        align-items: center;

        .countName {
          font-weight: 500;
          font-size: 22px;
          color: $primary-dark-blue;
        }

        .availableName {
          font-weight: 500;
          font-size: 14px;
          color: $secondary-grey;
        }
      }
    }

    .test-list:hover {
      border: 1px solid #B8C3C9;
      box-shadow: 0px 2px 2px rgba(159, 159, 159, 0.15);
      border-radius: 5px;

    }
  }
}

.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;

  // height: 100%;
  .empty-content {
    // display: flex;
    // justify-content: center;
    margin: 20px;

    .empty-text {
      margin: 20px 0px 0px 0px;
      font-weight: 400;
      font-size: 16px;
      color: $secondary-grey;
      text-align: center;
    }
  }
}

.instructionDrawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-title {
      font-weight: 600 !important;
      font-size: 16px;
      color: $primary-dark-blue !important;
    }
  }

  .ant-drawer-footer {
    padding: 0px;
    background-color: $drawer-footer-background-color;

    .footer {
      background: $drawer-footer-background-color;
      display: flex;
      padding: 16px 40px;
      justify-content: flex-end;
      align-items: center;
      margin: 0px;
    }
  }

  .difficult-level-container {
    p {
      margin: 0px;
    }

    .dividerOr {
      font-weight: 400;
      font-size: 12px;
      color: #37434E;
    }

    .detail-text {
      margin: 0px 0px 18px 0px;
      font-weight: 400;
      font-size: 13px;
      color: $primary-grey;
    }

    .content {
      .list {
        cursor: pointer;
        background: $fifth-level-grey;
        border: 1px solid $border-grey-color;
        border-radius: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 9px 0px;

        .difficult-text {
          flex-grow: 3;
          font-weight: 500;
          font-size: 14px;
          color: $primary-black;
          padding: 20px 24px;
        }
      }

      .active-list {

        background: $fifth-level-grey;
        border: 2px solid $primary-light-blue;
        border-radius: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 9px 0px;

        .difficult-text {
          flex-grow: 3;
          font-weight: 500;
          font-size: 16px;
          color: $primary-light-blue;
          padding: 20px 24px;
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 18px 24px;
  }

  .primary-submit-button {
    margin: 0px 0px 0px 13px;
  }
}

.testPageDrawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-title {
      font-weight: 600 !important;
      font-size: 16px;
      color: $primary-dark-blue !important;
    }
  }

  .ant-drawer-footer {
    padding: 0px;
    background-color: $drawer-footer-background-color;

    .footer {
      background: $drawer-footer-background-color;
      display: flex;
      padding: 16px 27px;
      justify-content: flex-end;
      align-items: center;
      margin: 0px;
    }
  }

  .ant-drawer-body {
    padding: 18px 24px;
  }
}

.reportDrawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-title {
      font-weight: 600 !important;
      font-size: 16px;
      color: $primary-dark-blue !important;
    }
  }

  .ant-drawer-footer {
    padding: 0px;
    background-color: $drawer-footer-background-color;

    .footer {
      background: $drawer-footer-background-color;
      display: flex;
      padding: 16px 27px;
      justify-content: flex-end;
      align-items: center;
      margin: 0px;
    }
  }

  .ant-drawer-body {
    padding: 12px 25px;
  }

  .report-container {
    .feedBack-header {
      font-weight: 500;
      font-size: 13px;
      color: $secondary-grey;
    }
  }
}

.primary-submit-button {
  background: $primary-light-blue !important;
  border-radius: 4px !important;
  color: $primary-white !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  height: 32px !important;
  padding: 3px 20px 5px 20px !important;
  margin: 0px 13px;

  &:hover {
    background: #196DE3;
  }
}

.primary-cancel-button {
  background: $drawer-footer-background-color !important;
  border-radius: 4px !important;
  color: $primary-black !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  height: 32px !important;
  padding: 3px 20px 5px 20px !important;
  margin: 0px 12px;
  border: 1px solid $border-grey-color;
}

.form-control {
  padding: 4px 0px;
  // margin-bottom: 15px;
  font-size: 13px !important;
  color: $primary-black;

  label {
    // margin: 6px 6px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 15px;
    color: $primary-black;
  }

  .ant-select {
    margin: 6px 0px;
  }

  textarea {
    margin: 6px 0px;
  }

  Input {
    margin: 6px 0px;
  }

  .ant-radio-group {
    margin: 6px 0px;
  }

  .quill {
    margin: 6px 0px;
  }

  p {
    margin: 0px;
  }
}

@media screen and (min-width:640px) {
  .viewSolution-container {
    display: flex;
    height: 100vh;

    p {
      margin: 0px;
    }

    .mobile-btn-toggle {
      display: none;
    }

    .question-data-container {
      padding: 24px 48px;
      width: 70%;
      background-color: $primary-background-color;

      .question-data-body {
        .ant-image {
          cursor: pointer;
        }

        .question-details-header {
          background: $primary-white;
          padding: 20px;
          margin: 0px 0px 2px 0px;
          border-radius: 4px;

          .question-number {
            font-weight: 600;
            font-size: 15px;
            color: $primary-black;

            .incorrect {
              font-weight: 500;
              font-size: 13px;
              color: $primary-red;
              padding: 0px 10px;
            }

            .correct {
              font-weight: 500;
              font-size: 13px;
              color: $primary-green;
              padding: 0px 10px;
            }

            .unanswered {
              font-weight: 500;
              font-size: 13px;
              color: $primary-orange;
              padding: 0px 10px;
            }
          }
        }

        .question-component-container {
          .question-details-body {
            background: $primary-white;
            padding: 20px;
            border-radius: 6px;
            height: calc(100vh - 200px);
            overflow: scroll;

            .ant-col {
              margin: 10px 5px;
            }

            .comprehension-label {
              font-weight: 600;
              font-size: 15px;
              color: $primary-dark-blue;
            }

            .question-label {
              font-weight: 500;
              font-size: 15px;
              color: $primary-dark-blue;
            }

            .option {
              padding: 10px;

              display: flex;
              align-items: center;

              .radio-span {
                height: 14px;
                width: 14px;
                background-color: $secondary-white;
                border: 1px solid $fourth-level-grey;
                border-radius: 100%;
                margin: 0px 15px 0px 0px;
              }

              .radio-text {
                font-weight: 400;
                font-size: 13px;
                color: $primary-grey;
              }
            }

            .correct-option {
              display: flex;
              align-items: center;
              padding: 10px;

              border: 1px solid $primary-green;
              border-radius: 5px;
              background-color: $correct-background-color;

              .radio-span {
                height: 14px;
                width: 14px;
                background-color: $primary-white;
                border: 5px solid $primary-green;
                border-radius: 100%;
                margin: 0px 15px 0px 0px;
              }

              .radio-text {
                font-weight: 400;
                font-size: 13px;
                color: $primary-grey;
              }
            }

            .incorrect-option {
              display: flex;
              align-items: center;
              padding: 10px;

              border: 1px solid $primary-red;
              border-radius: 5px;
              background-color: $incorrect-background-color;

              .radio-span {
                height: 14px;
                width: 14px;
                background-color: $primary-white;
                border: 5px solid $incorrect-radio-background-color;
                border-radius: 100%;
                margin: 0px 15px 0px 0px;
              }

              .radio-text {
                font-weight: 400;
                font-size: 13px;
                color: $primary-grey;
              }
            }

            .ant-image {
              width: 50%;
            }

            .Question_inline {

              table,
              tr,
              th,
              td {
                border: 1px solid black;
                padding: 4px;
                margin: 5px;

              }

              p {
                display: block;
              }

              img {
                max-width: 400px;
                max-height: 400px;
              }
            }
          }
        }

        .question-button {
          .question-button-wrapper {
            .outline-test-button {
              font-weight: 500;
              font-size: 13px;
              line-height: 24px;
              color: $primary-light-blue;
              border: 1px solid $primary-light-blue;
              background: $primary-white;
              margin: 0px 15px 0px 0px;
              border-radius: 4px;
            }

            .filled-test-button {
              font-weight: 500;
              font-size: 13px;
              line-height: 24px;
              color: $primary-white;
              border: 1px solid $primary-light-blue;
              background: $primary-light-blue;
              border-radius: 4px;

            }

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0px;

            .back-button-container {}

            .question-change-button-container {}
          }
        }
      }
    }

    .question-paper-data {
      width: 30%;

      .question-button-content {
        height: calc(100vh - 15vh) !important;
        padding: 19px 19px;
        background: $primary-white;
        height: 602px;
        // margin: 0px 0px 4px 0px;
        height: 85vh;

        .test-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $primary-black;
          text-transform: capitalize;

        }

        .question-pad-details {
          p {
            margin: 0px;
          }

          .question-header {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $primary-black;
          }

          .total-question {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $secondary-grey;
            padding: 5px 0px;
          }

          .marker-details {
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: $secondary-grey;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .marker-data {
              padding: 0px 20px 0px 0px;

              .ant-badge {
                border-radius: 2px;
                width: 8px;
                height: 8px;
              }

              .ant-badge-status-dot {
                border-radius: 2px;
                width: 8px;
                height: 8px;
              }

              .ant-badge-status-success {
                background: $primary-green;
                border: 1px solid $primary-green;
              }

              .ant-badge-status-warning {
                background: $primary-red;
                border: 1px solid $primary-red;
              }

              .ant-badge-status-default {
                background: $primary-white;
                border: 1px solid $fourth-level-grey;
              }

              .count-data {
                padding: 0px 2px;
              }
            }
          }

          .question-button-status {
            max-height: calc(100vh - 200px);
            overflow: scroll;

            .ant-col {
              margin: 10px 0px 10px 0px;
            }

            .ant-btn {
              padding: 4px 6px;
            }

            .correct-button {
              // max-width: 50%;
              // min-width: 50%;
              // max-width: 32px;
              // min-width: 32px;
              width: 30px;
              height: 30px;
              font-size: 13px;
              background: $primary-green;
              color: $primary-white;
              border: 1px solid $primary-green;
              border-radius: 3px;
            }

            .incorrect-button {
              // max-width: 50%;
              // min-width: 50%;
              width: 30px;
              height: 30px;
              font-size: 13px;
              background: $primary-red;
              color: $primary-white;
              border: 1px solid $primary-red;
              border-radius: 3px;
            }

            .default-button {
              // max-width: 50%;
              // min-width: 50%;
              width: 30px;
              height: 30px;
              background: $primary-white;
              border: 1px solid $fourth-level-grey;
              border-radius: 3px;
              font-size: 12px;
              color: #021f55;
              padding: 0px;
            }
          }
        }
      }
    }
  }
}

.order-page-container {
  // margin: 15px 25px;
  padding: 24px;

  p {
    margin: 0px;
  }

  .header-container {
    margin: 16px 0px;

    .header-content {
      background-color: $primary-white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 25px;
      border: 1px solid $border-grey-color;
      border-radius: 10px;

      .header-text {
        font-weight: 600;
        font-size: 22px;
        color: $primary-black;
        margin: 0px;
      }
    }
  }

  .body-container {
    overflow-y: scroll;
    height: calc(100vh - 278px);

    .body-content {
      background: $primary-white;
      padding: 18px 22px;
      border: 1px solid $border-grey-color;
      border-radius: 5px;
      margin: 2px 0px;
      cursor: pointer;

      .orderText {
        font-weight: 500;
        font-size: 14px;
        color: $primary-black;
      }

      .orderMessage {
        font-weight: 500;
        font-size: 13px;
        color: $primary-light-blue;
        padding: 8px 0px;

        .success {
          color: $primary-light-blue;
        }

        .failed {
          color: $primary-red;
        }
      }

      .orderTime {
        font-weight: 500;
        font-size: 12px;
        color: $secondary-grey;
      }
    }

    .body-content:hover {
      border: 1px solid #B8C3C9;
      box-shadow: 0px 2px 2px rgba(159, 159, 159, 0.15);
      border-radius: 5px;
    }
  }
}

.singleOrder-container {
  margin: 15px 25px;

  p {
    margin: 0px;
  }

  .header-container {
    margin: 16px 0px;

    .header-content {
      background: $primary-white;
      padding: 18px 22px;
      border: 1px solid $border-grey-color;
      border-radius: 5px;
      margin: 2px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .details {
        .orderText {
          font-weight: 500;
          font-size: 14px;
          color: $primary-black;
        }

        .orderMessage {
          font-weight: 500;
          font-size: 13px;
          color: $primary-light-blue;

          .success {
            color: $primary-light-blue;
          }

          .failed {
            color: $primary-red;
          }
        }

        .totalItems {
          font-weight: 500;
          font-size: 14px;
          color: $primary-grey;
          padding: 8px 0px;
        }

        .orderTime {
          font-weight: 500;
          font-size: 12px;
          color: $secondary-grey;
        }
      }
    }
  }

  .body-container {
    .body-wrapper {
      .subHeader {
        font-weight: 500;
        font-size: 14px;
        color: $primary-black;
      }

      .body-content {
        background-color: $primary-white;
        padding: 10px 16px;
        border: 1px solid $border-grey-color;
        border-radius: 5px;
        // cursor: pointer;


        .content-details {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: 1px solid $fifth-level-grey;

          .details {
            .fullTest {
              font-weight: 600;
              font-size: 12px;
              // background-color: $primary-light-pink;
              // border: 1px solid $border-pink-color;
              border-radius: 2px;
              padding: 4px 0px;
              color: $primary-dark-orange;
              text-transform: capitalize;

            }

            .subTest {
              font-weight: 600;
              font-size: 12px;
              // background-color: $primary-green-background;
              // border: 1px solid $primary-green-border;
              border-radius: 2px;
              padding: 4px 0px;
              color: $secondary-green;
              text-transform: capitalize;

            }

            .chapTest {
              font-weight: 600;
              font-size: 12px;
              // background-color: $primary-purple-background;
              // border: 1px solid $primary-purple-border;
              border-radius: 2px;
              padding: 4px 0px;
              color: $primary-purple;
              text-transform: capitalize;

            }

            .examName {
              font-weight: 600;
              font-size: 12px;
              // color: #021F55;
              color: #1B2228;
              // padding: 0px 8px;
              text-transform: capitalize;
            }

            .testName {
              font-weight: 600;
              font-size: 14px;
              color: $primary-dark-blue;
              padding: 12px 0px 12px 0px;
              text-transform: capitalize;
            }
          }

          .total {
            padding: 12px 12px 5px 12px;
            font-weight: 600;
            font-size: 13px;
            color: $primary-dark-blue;
          }
        }

        .sub-total {
          text-align: right;

          .sub-text {
            font-weight: 400;
            font-size: 12px;
            color: $primary-black;
          }

          .sub-amount {
            padding: 0px 12px 0px 20px;
            font-weight: 600;
            font-size: 14px;
            color: $primary-dark-blue;
          }
        }
      }

      // .body-content:hover{
      //   border: 1px solid #B8C3C9;
      //   box-shadow: 0px 2px 2px rgba(159, 159, 159, 0.15);
      //   border-radius: 5px;
      // }
    }
  }
}

// .endTest-modal{
//   .ant-btn-primary{
//     ;
//   }
// }


::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #77b7f3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .new-exams {
    .card-scroll {
      .ant-row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .ant-col-6 {
          flex: none;
          width: 31% !important;
          margin: 1% !important;
          max-width: none;
        }

        .new-exam-container {
          margin: 0px !important;
        }
      }
    }
  }

  .home-test {
    .card-scroll {
      .ant-col-8 {
        flex: none;
        max-width: none;
        width: 48%;
        max-width: none;
        margin: 1%;
      }
    }
  }

  .test-details {

    // display: contents !important;
    // justify-content: space-between !important;
    .total-marks {
      font-size: 12px !important;
      font-weight: 500 !important;
      line-height: 15px !important;
      // border-right: 1px solid $secondary-grey;
      padding: 0px 5px;
    }

    .total-mins {
      border-right: 1px solid #60717a;
      padding: 0px 5px;
    }

    .total-question {
      padding: 0px 5px 0px 0px !important;
    }

    .single-test-free-button {
      margin-top: 10px;
    }
  }
}

.total-marks {
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  padding: 0px 10px 0px 7px;
  // border-left: 1px solid $secondary-grey;
  margin-right: 10px;
}

.question-view-solution-container {
  padding: 10px 0px;

  .view-solution-for-question {
    padding: 10px 5px;
  }
}

.get-all-exam-container {
  .get-all-exam-wrapper {
    padding: 24px;

    .breadCrumbs-container {
      .ant-breadcrumb-link {
        cursor: pointer;
      }
    }

    .header-container {
      .header-card {
        background-color: $primary-white;
        border: 1px solid $border-grey-color;
        border-radius: 10px;
        padding: 25px;
        margin: 18px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card-details {
          display: flex;

          .groupExam {
            width: 50px;
            height: 50px;
          }

          .header-text {
            padding: 20px;
          }
        }

        .search {
          .ant-input-affix-wrapper {
            background: #FFFFFF;
            border: 1px solid #E1E7EA;
            border-radius: 4px;
            height: 32px;

            .ant-input {
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #1B2228;
            }
          }
        }

        .header-text {
          font-weight: 600;
          font-size: 22px;
          color: $primary-black;
        }
      }

    }

    .body-container {
      .body-wrapper {
        height: calc(100vh - 282px);
        overflow: scroll;
      }


    }
  }
}

.breadCrumb-data {
  text-transform: capitalize;
}

.popular-test-container {
  .popular-test-wrapper {
    padding: 20px 26px;

    .breadCrumbs-container {
      .ant-breadcrumb-link {
        cursor: pointer;
      }
    }

    .header-container {
      .header-card {
        background-color: $primary-white;
        border: 1px solid $border-grey-color;
        border-radius: 10px;
        padding: 3px 25px;
        margin: 16px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-text {
          font-weight: 600;
          font-size: 22px;
          color: $primary-black;
        }
      }

    }

    .body-container {
      .body-wrapper {
        height: calc(100vh - 306px);
        overflow: scroll;
      }


    }
  }
}

.popular-test-component-container {
  .popular-test-component-wrapper {
    .popular-test-cart {
      border: 1px solid $border-grey-color;
      border-radius: 5px;
      background-color: $primary-white;
      display: flex;
      align-items: center;
      margin: 5px 10px 5px 0px;
      padding: 10px 20px;

      p {
        margin: 0px;
      }

      .content {
        flex-grow: 3;

        // .exam-title{
        //   font-size: 14px;
        //   span{
        //     font-size: 14px;
        //   }
        // }
        .test-name {
          font-weight: 600;
          font-size: 14px;
          color: $primary-dark-blue;
          text-transform: capitalize;
        }

        .question-details {
          display: flex;
          font-weight: 500;
          font-size: 11px;
          color: $primary-grey;
          padding: 6px 0px;
        }
      }

      .button {}
    }
  }
}

.footer-page {
  width: 100%;
  // position: absolute;
  bottom: 0;
}

.header-text {
  color: $primary-dark-blue;
  font-weight: 600;
  font-size: 20px;
  padding: 7px;
  margin: 0px;
  text-align: center;
  padding: 20px 0px;
  text-transform: capitalize;
}

.sub-header-text {
  color: $primary-dark-blue;
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
}

.privacy-policy-container {

  .privacy-policy-wrapper {
    p {
      margin: 0px;
    }

    .privacy-policy-body {
      min-height: 380px;

      .privacy-policy-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .content-wrapper {
          width: 600px;
        }
      }
    }
  }
}

.aboutUs-container {
  .aboutUs-wrapper {
    p {
      margin: 0px;
    }

    .aboutUs-body {
      min-height: 380px;
    }

    .aboutUs-content {
      display: flex;
      justify-content: center;
      align-items: center;

      .content-wrapper {
        width: 600px;
      }
    }
  }
}

.app-logo {
  img {
    cursor: pointer;
  }
}

.randc-container {
  .randc-wrapper {
    p {
      margin: 0px;
    }

    .randc-body {
      min-height: 380px;

      .randc-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .content-wrapper {
          width: 600px;
        }
      }
    }
  }
}

.ant-layout-sider-trigger {
  display: none;
}

.ant-layout-sider-children {
  position: sticky;
  top: 0;
  height: 100vh;
  background: #ffff;
}


.logo-header {
  display: none;
}

.close-icon {
  display: none;
}


/*************** Mobile Responsive Styles **************/
/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

  /* CSS */

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .left-header {
    display: flex;
    align-items: center;

    .hamburger {
      display: inline-flex !important;

      img {
        width: 28px;
      }
    }
  }

  .close-icon {
    display: inline-block;
  }

  .site-layout-slider {
    .ant-layout-sider-children {
      display: block;
    }
  }

  .responsive-nabar-collapse {
    .ant-layout-sider-children {
      display: none;
    }
  }

  .logo {
    position: relative;

    .close-icon {
      position: absolute;
      left: 9px;
      /* height: 100%; */
      top: 50%;
      transform: translate(0%, -50%);

      img {
        width: 25px;
      }
    }
  }

  .site-layout-slider {
    .ant-layout-sider-children {
      background: url('./assets/images/login-bg.svg') no-repeat center bottom, #fff;
      width: 100%;
      // background: #fff;
      z-index: 100;
      left: 0vw;
      position: absolute;
      top: 0;
      height: 100vh;
    }
  }

  .container-wrapper {
    overflow-y: unset !important;
    height: 100% !important;
  }

  .logo-header {
    display: inline-block;
    width: 180px;
  }

  .logo-container .ant-divider {
    margin: 0px 10px;
    margin-right: 15px;
  }

  .home-container {
    padding: 12px;

    .random-header {
      padding: 12px 14px;
    }
  }

  /***** Home Test *****/
  .home-test {
    .card-scroll {
      margin-top: 12px;

      .popular-test-component-container {
        margin-bottom: 5px;
      }
    }
  }

  .popular-test-component-container .popular-test-component-wrapper .popular-test-cart {
    margin: 0px;
    margin-bottom: 5px;
  }

  /***** Exam page *****/

  .get-all-exam-container {
    padding: 12px;

    .header-card {
      .header-text {
        font-size: 16px !important;
        padding: 0px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        padding-left: 5px !important;
      }
    }

    .get-all-exam-wrapper {
      padding: 0px;

      .body-container {
        .body-wrapper {
          height: auto;
          overflow: unset;
        }
      }
    }

    .new-exam-container {
      margin: 0px;
      margin-bottom: 5px;
    }
  }

  .header-container {
    margin: 10px 0px;

    .header-card {
      margin: 0px !important;
      padding: 10px !important;

      .card-details {
        width: 50%;
        display: flex;
        align-items: center;

        img {
          width: 40px !important;
          height: 40px !important;
        }

        .header-text {
          font-size: 16px !important;
          padding: 0px !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          padding-left: 5px !important;
        }
      }

      .search {
        width: 50%;
      }
    }
  }

  .new-exams {
    .new-exam-container {
      margin: 0px !important;
    }
  }

  /***** Test Instruction Container *****/
  .test-instruction-container {
    padding: 12px;

    .breadcrumb-details {
      margin-bottom: 10px;
    }

    .test-instruction-header {
      .test-instruction-header-container {
        padding: 12px;
        flex-direction: column;
      }

      .instruction-purchase {
        margin-top: 10px;
      }
    }

    // .instruction-total{
    //   .total-question{
    //     font-size: 11px!important;
    //   }
    //   .total-marks{
    //     font-size: 11px!important;
    //   }
    //   .total-mins{
    //     font-size: 11px!important;
    //   }
    // }
    .test-instruction-Details {
      padding: 12px !important;
    }
  }

  /***** Bought Test Page *****/
  .order-page-container {
    padding: 12px;

    .header-container {
      margin: 0px;
      margin-bottom: 16px;

      .header-content {
        padding: 12px;

        .ant-image {
          width: 30%;
        }

        .header-text {
          width: 70%;
          font-size: 16px;
          text-align: left;
        }
      }
    }

    .empty-container {
      margin-top: 50px;

      .empty-content {
        .ant-image {
          display: flex;
          justify-content: center;

          img {
            width: 70%;
          }
        }
      }
    }
  }


  /******** Instruction Drawer *********/
  .instructionDrawer {
    .ant-drawer-content-wrapper {
      width: 70% !important;

      .list,
      .active-list {
        .ant-image {
          // temp - image size need to be corrected by UI team
          display: none;
        }
      }
    }

    .footer {
      padding: 10px 20px !important;
    }
  }


}


@media (max-width: 640px) {
  .endTest-modal {
    width: 300px;
  }

  .test-page-container {
    overflow: hidden;
    display: flex;
    background: $primary-background-color;
    height: 100vh;
    position: relative;

    .question-container {
      width: 100%;
      padding: 20px;
      margin: 0px;

      .question-container-body {
        .question-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0px;

          .timer-content {
            display: flex;
            padding: 0px 20px;
          }
        }

        .question-details {

          // background: $primary-white;
          .question-details-header {
            background: $primary-white;

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;

            .question-number {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: $primary-dark-blue;
            }

            .question-flags {
              .status {
                color: $secondary-white;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;

                // margin: 0px 20px;
                .wrong-status {
                  background: $primary-red;
                  padding: 5px 10px;
                  border-radius: 3px;
                  margin: 0px 8px;
                }

                .correct-status {
                  background: $primary-green;
                  padding: 5px 10px;
                  border-radius: 3px;
                  margin: 0px 8px;
                }
              }

              .bookmark {
                cursor: pointer;
                padding: 10px;

                span {
                  display: none;
                  padding: 5px 0px 0px 10px;
                }
              }

              .report {
                padding: 10px;
                cursor: pointer;

                .report-text {
                  display: none;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 16px;
                  color: $primary-black;
                  // padding:;
                }
              }
            }
          }

          .question-details-content {}
        }
      }

      .question-details-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 15vh;

        .outline-test-button {
          margin: 10px;

          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: $primary-light-blue;
          border: 1px solid $primary-light-blue;
          background: $primary-white;
          // margin: 0px 15px 0px 0px;
          border-radius: 4px;
        }

        .mark-review-button {
          margin: 10px;

          color: #ffff;
          background-color: #FFA443;
          border: #FFA443;
          // margin: 0px 15px 0px 0px;
          border-radius: 4px;
        }

        .filled-test-button {
          margin: 10px;

          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: $primary-white;
          background-color: #07bd81 !important;
          border: #07bd81 !important;
          // margin: 0px 15px 0px 0px;
          border-radius: 4px;

        }
      }
    }

    .show-panel {
      transform: translateX(0%) !important;
    }

    .question-buttons {
      // display: none;
      // width: 30%;
      background: #F5F6F8;
      position: absolute;
      width: 100%;
      box-shadow: #00000017 -1px 1px 3px 1px;
      transform: translateX(100%);

      .mobile-btn-toggle {
        display: inline;
        position: absolute;
        width: 20px;
        height: 20px;
        left: -36px;
        top: 5px;
        // background: #faad14;
      }

      .close-btn-toggle {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 10px;
        top: 0;
        margin: 5px;

        .closeArrow {
          rotate: 180deg;
        }
      }

      .question-button-content {
        height: calc(100vh - 15vh) !important;
        padding: 24px 19px;
        background: $primary-white;
        height: 602px;
        margin: 0px 0px 4px 0px;
        height: 85vh;

        .test-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $primary-black;
          text-transform: capitalize;

        }

        .question-pad-details {
          p {
            margin: 0px;
          }

          .question-header {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: $primary-black;
          }

          .total-question {
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: $secondary-grey;
            padding: 5px 0px;
          }

          .marker-details {
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: $secondary-grey;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .marker-data {
              padding: 0px 20px 0px 0px;
              font-size: 13px;

              .ant-badge-status-dot {
                border-radius: 2px;
                width: 8px;
                height: 8px;
              }

              .ant-badge-status-success {
                background-color: $primary-dark-blue;
                border: 1px solid $fourth-level-grey;
              }

              .ant-badge-status-warning {
                background-color: $primary-orange;
              }

              .ant-badge-status-default {
                border: 1px solid $fourth-level-grey;
                background-color: $primary-white;
              }
            }
          }

          .question-button-status {
            max-height: calc(100vh - 15vh - 200px);
            overflow: scroll;

            .ant-col {
              margin: 10px 0px 10px 0px;
            }

            .current-question {
              border: 1px solid $primary-green !important;
              background-color: $primary-green !important;
              color: $primary-white !important;
            }

            .answered-question {
              border-radius: 3px;
              border: 1px solid $border-grey-color;
              background-color: $primary-dark-blue;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: $primary-white;
              width: 30px;
              height: 30px;
              padding: 0px;

              .badge {
                position: absolute;
                bottom: -6px;
                right: -6px;
              }
            }

            .not-answered-question {
              background-color: $primary-white;
              border-radius: 3px;
              border: 1px solid $border-grey-color;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: $primary-dark-blue;
              width: 30px;
              height: 30px;
              padding: 0px;

              .badge {
                position: absolute;
                bottom: -6px;
                right: -6px;
              }
            }
          }
        }
      }

      .question-panel-footer-button {
        background: $primary-white;
        // padding: 0px 22px 24px 21px;
        padding: 10spx;
        height: 15vh;
        display: flex;
        align-items: center;
        justify-content: center;

        // flex-direction: column;
        .footer-link-page {
          display: none;
          // display: flex;
          cursor: pointer;
          justify-content: space-between;
          align-items: center;
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: $primary-light-blue;
          text-decoration: underline;
          padding: 0px 25px;
        }

        .footer-submit-button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 25px;

          .end-test-pause-button {
            width: 30%;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $primary-light-blue;
            background-color: $primary-white;
            border: 1px solid $primary-light-blue;
            border-radius: 4px;
          }

          .end-test-submit-button {
            // width: 314px;
            // width: 30%;

            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $primary-white;
            background-color: $primary-light-blue;
            border: 1px solid $primary-light-blue;
            border-radius: 4px;
          }

          .time-over-button {
            background-color: #07bd81 !important;
            border: #07bd81 !important;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $primary-white;
            border: 1px solid $primary-light-blue;
            border-radius: 4px;
            width: 30%;
          }
        }
      }
    }
  }
}

.ava-modal {
  .ant-modal-header {
    display: block;
    border-radius: 10px 10px 0px 0px;
    padding: 16px 10px;
    margin: 0px 16px;

    .ant-modal-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #021F55;
    }

  }

  .ant-modal-body {
    padding-top: 0px;
  }
}

.available-coupon-wrapper {
  p {
    margin: 0;
  }

  .available-card {
    border-bottom: 1px solid #E1E7EA;
    padding: 16px;

    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content-wrapper {
          padding: 0px 12px;
        }

        .couponName {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #1B2228;
          margin: 6px;
          text-transform: uppercase;
        }

        .discount {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: flex-end;
          color: #FFA443;
          padding: 4px 10px;
          background: rgba(255, 164, 67, 0.2);
          border-radius: 6px;
        }
      }

    }

    .links {

      .apply-coupon {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #2D81F7;
        text-decoration: underline;
      }
    }

    .expires {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: flex-end;
      color: #37434E;
      margin-top: 15px;
    }

    .discount-msg {
      padding: 5px 0px;
      color: #07BD81;
      font-size: 12px;
    }

    .warning {
      // padding-: 5px 0px;
      color: #333333;
      padding-left: 5px;

    }
  }

  .available-card:last-child {
    border-bottom: none;
  }
}

@media (max-width:640px) {
  .viewSolution-container {
    overflow: hidden;
    position: relative;
    display: flex;
    height: 100vh;

    p {
      margin: 0px;
    }

    .question-data-container {
      padding: 20px;
      width: 100%;
      background-color: $primary-background-color;

      .question-data-body {
        .question-details-header {
          background: $primary-white;
          padding: 20px;
          margin: 0px 0px 2px 0px;
          border-radius: 4px;

          .question-number {
            font-weight: 600;
            font-size: 15px;
            color: $primary-black;

            .incorrect {
              font-weight: 500;
              font-size: 13px;
              color: $primary-red;
              padding: 0px 10px;
            }

            .correct {
              font-weight: 500;
              font-size: 13px;
              color: $primary-green;
              padding: 0px 10px;
            }

            .unanswered {
              font-weight: 500;
              font-size: 13px;
              color: $primary-orange;
              padding: 0px 10px;
            }
          }
        }

        .question-component-container {
          .question-details-body {
            background: $primary-white;
            padding: 20px;
            border-radius: 6px;
            height: calc(100vh - 200px);
            overflow: scroll;

            .ant-col {
              margin: 10px 5px;
            }

            .question-label {
              font-weight: 500;
              font-size: 15px;
              color: $primary-dark-blue;
            }

            .option {
              padding: 10px;

              display: flex;
              align-items: center;

              .radio-span {
                height: 14px;
                width: 14px;
                background-color: $secondary-white;
                border: 1px solid $fourth-level-grey;
                border-radius: 100%;
                margin: 0px 15px 0px 0px;
              }

              .radio-text {
                font-weight: 400;
                font-size: 13px;
                color: $primary-grey;
              }
            }

            .correct-option {
              display: flex;
              align-items: center;
              padding: 10px;

              border: 1px solid $primary-green;
              border-radius: 5px;
              background-color: $correct-background-color;

              .radio-span {
                height: 14px;
                width: 14px;
                background-color: $primary-white;
                border: 5px solid $primary-green;
                border-radius: 100%;
                margin: 0px 15px 0px 0px;
              }

              .radio-text {
                font-weight: 400;
                font-size: 13px;
                color: $primary-grey;
              }
            }

            .incorrect-option {
              display: flex;
              align-items: center;
              padding: 10px;

              border: 1px solid $primary-red;
              border-radius: 5px;
              background-color: $incorrect-background-color;

              .radio-span {
                height: 14px;
                width: 14px;
                background-color: $primary-white;
                border: 5px solid $incorrect-radio-background-color;
                border-radius: 100%;
                margin: 0px 15px 0px 0px;
              }

              .radio-text {
                font-weight: 400;
                font-size: 13px;
                color: $primary-grey;
              }
            }

            .ant-image {
              width: 50%;
            }

            .Question_inline {

              table,
              tr,
              th,
              td {
                border: 1px solid black;
                padding: 4px;
                margin: 5px;

              }

              p {
                display: block;
              }

              img {
                max-width: 400px;
                max-height: 400px;
              }
            }
          }
        }

        .question-button {
          .question-button-wrapper {
            .outline-test-button {
              font-weight: 500;
              font-size: 13px;
              line-height: 24px;
              color: $primary-light-blue;
              border: 1px solid $primary-light-blue;
              background: $primary-white;
              margin: 0px 15px 0px 0px;
              border-radius: 4px;
            }

            .filled-test-button {
              font-weight: 500;
              font-size: 13px;
              line-height: 24px;
              color: $primary-white;
              border: 1px solid $primary-light-blue;
              background: $primary-light-blue;
              border-radius: 4px;

            }

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0px;

            .back-button-container {}

            .question-change-button-container {}
          }
        }
      }
    }

    .show-panel {
      transform: translateX(0%) !important;
    }

    .question-paper-data {
      // width: 30%;
      position: absolute;
      width: 100%;
      box-shadow: #00000017 -1px 1px 3px 1px;
      transform: translateX(100%);
      background-color: blue;

      .mobile-btn-toggle {
        display: inline;
        position: absolute;
        width: 20px;
        height: 20px;
        left: -36px;
        top: 5px;
        // background: #faad14;
      }

      .close-btn-toggle {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 10px;
        top: 0;
        margin: 5px;

        .closeArrow {
          rotate: 180deg;
        }
      }

      .question-button-content {
        height: 100vh !important;
        padding: 19px 19px;
        background: $primary-white;
        height: 602px;
        // margin: 0px 0px 4px 0px;
        height: 85vh;

        .test-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $primary-black;
          text-transform: capitalize;

        }

        .question-pad-details {
          p {
            margin: 0px;
          }

          .question-header {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $primary-black;
          }

          .total-question {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $secondary-grey;
            padding: 5px 0px;
          }

          .marker-details {
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: $secondary-grey;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .marker-data {
              padding: 0px 20px 0px 0px;

              .ant-badge {
                border-radius: 2px;
                width: 8px;
                height: 8px;
              }

              .ant-badge-status-dot {
                border-radius: 2px;
                width: 8px;
                height: 8px;
              }

              .ant-badge-status-success {
                background: $primary-green;
                border: 1px solid $primary-green;
              }

              .ant-badge-status-warning {
                background: $primary-red;
                border: 1px solid $primary-red;
              }

              .ant-badge-status-default {
                background: $primary-white;
                border: 1px solid $fourth-level-grey;
              }

              .count-data {
                padding: 0px 2px;
              }
            }
          }

          .question-button-status {
            max-height: calc(100vh - 200px);
            overflow: scroll;

            .ant-col {
              margin: 10px 0px 10px 0px;
            }

            .ant-btn {
              padding: 4px 6px;
            }

            .correct-button {
              // max-width: 50%;
              // min-width: 50%;
              // max-width: 32px;
              // min-width: 32px;
              width: 30px;
              height: 30px;
              font-size: 13px;
              background: $primary-green;
              color: $primary-white;
              border: 1px solid $primary-green;
              border-radius: 3px;
            }

            .incorrect-button {
              // max-width: 50%;
              // min-width: 50%;
              width: 30px;
              height: 30px;
              font-size: 13px;
              background: $primary-red;
              color: $primary-white;
              border: 1px solid $primary-red;
              border-radius: 3px;
            }

            .default-button {
              // max-width: 50%;
              // min-width: 50%;
              width: 30px;
              height: 30px;
              background: $primary-white;
              border: 1px solid $fourth-level-grey;
              border-radius: 3px;
              font-size: 12px;
              color: #021f55;
              padding: 0px;
            }
          }
        }
      }
    }
  }
}

.breadCrumbs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  // padding-bottom: 10px;
  .ant-breadcrumb-link {
    cursor: pointer;

    .content {
      text-transform: capitalize;
    }

  }
}

.pageNotFound-wrapper {
  width: 100%;
  height: 100vh;

  .pageNotFound-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    .ant-image {
      margin-top: 165px;
      // margin-left: 37%;
      margin-bottom: 22px;
    }

    .goToHome {
      button {
        background: $primary-light-blue ;
        color: $primary-white;
        border-radius: 4px;
      }
    }
  }
}

.test-total-price {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #1B2228;
  margin-bottom: 12px !important;

}

.test-details {
  .question-details {
    display: flex;
    font-weight: 500;
    font-size: 11px;
    color: #37434e;
    padding: 6px 0px;
  }
}

.profile-container {
  padding: 24px;

  .random-header {
    background-color: $primary-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 26px;
    margin-bottom: 16px;
    border-radius: 10px;

    p {
      margin: 0px;
    }

    .user-container {
      display: flex;

      .userProfile {
        // height: 100%;
        // width: 28px;
        text-align: center;

        .user-profile-icon{
          width: 50px !important;
          border-radius: 50px !important;
          height: 50px !important;
        }
        .firstName {
          width: 50px;
          height: 50px;
          background-color: #DBE4EF;
          border-radius: 50%;
          border: 2px solid #DBE4EF;
          color: #37434E;
          font-weight: 600;
          font-size: 20px;
          line-height: 44px;
          padding: 2px;
        }
      }

      .user-details {
        margin: 0px 0px 0px 9px;

        .user-name {
          font-weight: 600;
          font-size: 18px;
          line-height: 17px;
          color: $primary-dark-blue;
          padding: 6px 0px;
          text-transform: capitalize;

        }

        .tag {
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
          color: $secondary-grey;
        }
      }
    }
  }

  @media screen and (max-width:640px) {
    .quiz-content {
      .ant-row {
        display: flex;
        justify-content: center;
      }
    }
  }

  .quiz-content {
    overflow: scroll;
    margin: 21px 0px 22px 2px;

    // display: flex;
    .quiz-details-card {
      // margin-left: 20px;
      display: flex;
      // justify-content: space-between;
      // width: 100%;
      background: #FFFFFF;
      border: 0.886792px solid #E1E7EA;
      border-radius: 8px;
      padding: 12px;
      margin-bottom: 4px;
      margin-right: 16px;
      justify-content: center;
      cursor: pointer;

      p {
        margin: 0px;
      }

      .card-image {
        .ant-image {
          .ant-image-img {
            height: 44px;
            width: 25px;
          }
        }
      }

      .card-details {
        padding-left: 14px;
        display: flex;
        align-items: center;

        .card-header {
          font-weight: 600;
          font-size: 14px;
          color: #1B2228;
        }

        .card-marks {
          font-weight: 600;
          font-size: 18px;
          color: #021F55;

          span {
            font-weight: 600;
            font-size: 12px;
            color: #7A8B94;
          }
        }
      }
    }
  }

  .personal-details-wrapper {
    background: #FFFFFF;
    border-radius: 8px;
    height: 350px;
    padding: 20px;

    .personal-details {
      p {
        font-size: 14px;
        color: #2D81F7;
        font-weight: 600;
      }

      .personal-content {
        padding-top: 20px;
        color: #60717A;
        font-weight: 500;
        font-size: 13px;
      }

      .personal-answer {
        padding-top: 20px;
        font-weight: 500;
        font-size: 14px;
        color: #1B2228;

        .reset-btn {
          // padding-left: 75px;
          font-weight: 600;
          font-size: 13px;
          text-decoration-line: underline;
          color: #2D81F7;
          // margin-top: -20px;
          cursor: pointer;
        }
      }
    }

    // @media screen and (max-width:640px){
    //   .profile-drawer{

    //     .ant-drawer-content-wrapper{
    //       width: 70% !important;
    //     }
    //   }
    // }
    .profile-drawer {

      // .ant-drawer-footer{
      //   margin-top: 23px !important;
      //   display: flex !important;
      //   justify-content: flex-end !important;
      // }
      .ant-drawer-footer {

        .footer-profile {
          display: flex;
          justify-content: flex-end;

          .footer-button {
            margin-top: 23px !important;
            display: flex !important;
            justify-content: flex-end !important;
          }

        }
      }

      .profile-img-wrapper {

        //     .ant-upload-list-picture-card-container {
        //       display: inline-block;
        // /* width: 70px; */
        // height: 70px;
        // margin: 0 8px 8px 0;
        // vertical-align: top;
        //     }

        //     .ant-upload-list-item.ant-upload-list-item-undefined.ant-upload-list-item-list-type-picture-card{

        //     }
        //     .ant-upload-list-picture-card .ant-upload-list-item {
        //       height: 70%;
        //       margin: 0;
        //       width: 70%;
        //     }

        .upload-button {
          color: #2D81F7;
          text-decoration-line: underline;
          font-weight: 600;
          font-size: 14px;
        }

        button.ant-btn.ant-btn-text.upload-button {
          color: #dddd1b;
        }
      }

      .profile-form-wrapper {
        .form-control {
          .gender-radio {
            display: flex;
            color: #60717A;
          }
        }
      }
    }
  }
}

.profile-img-wrapper {
  display: flex;
  align-items: center;

  .avatar {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
    background-color: aliceblue;
    border-radius: 50%;

    .no-profile-image {
      padding: 8px;
      font-size: 11px;
      margin-top: 20px;
    }
  }

  .ant-upload-list {
    display: none;
  }

  .ant-upload p {
    margin-left: 26px;
    padding-top: 10px;
    color: #2D81F7;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;

    }
  }
}





.profile-drawer {

  .ant-drawer-footer {
    .footer-profile {
      .footer-button {
        margin-top: 23px !important;
        display: flex !important;
        justify-content: flex-end !important;
      }

    }
  }

}

.reset-drawer {

  .ant-drawer-footer {
    .footer-profile {
      .footer-button {
        margin-top: 23px !important;
        display: flex !important;
        justify-content: flex-end !important;
      }

    }
  }

}

.ant-tabs-nav {
  .user-onboarding-wrapper {
    margin-left: 103px !important;
    width: 328px !important;
  }
}

.ant-tabs-nav-wrap {
  .user-onboarding-wrapper {
    margin-left: 60px !important;
  }
}

// .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
//   .user-onboarding-wrapper{
//     left: 82px !important;
//     width: 164px !important;
//     background: #2D81F7 !important;
//   }
//   div#rc-tabs-1-tab-2{
//     padding: 10px !important;
//     font-weight: 600 !important;
//     font-size: 13px !important;
//     line-height: 100% !important;
//     color: #021F55 !important;
//   }
// }
.ant-tabs-nav-wrap {
  display: flex !important;
  // margin-top: 20px !important;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  // width: 164px !important;
  background: #2D81F7;
}

.ant-tabs-tab {

  // div#rc-tabs-1-tab-2{
  &active {
    color: #021F55;
    font-weight: 600;
    font-size: 13px;
    line-height: 100%;
  }

  // }
}

// .ant-tabs-tab-active{
//   color: #021F55;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 100%;
// }

.ant-divider.ant-divider-vertical.user-divider {
  width: 1px;
  height: 56px;
  background: #B8C3C9;
  margin: 0px 59px;
}


.popular-test-cart {
  border: 1px solid $border-grey-color;
  border-radius: 5px;
  background-color: $primary-white;
  display: flex;
  align-items: center;
  margin: 5px 10px 5px 0px;
  padding: 10px 20px;

  p {
    margin: 0px;
  }

  .content {
    flex-grow: 3;

    // .exam-title{
    //   font-size: 14px;
    //   span{
    //     font-size: 14px;
    //   }
    // }
    .test-name {
      font-weight: 600;
      font-size: 14px;
      color: $primary-dark-blue;
      text-transform: capitalize;
    }

    .question-details {
      display: flex;
      font-weight: 500;
      font-size: 11px;
      color: $primary-grey;
      padding: 6px 0px;
    }
  }

  .button {}
}

.attempted-test-header {
  background: #FFFFFF;
  border: 1px solid #E1E7EA;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;

  p {
    margin: 0px;
    font-weight: 600;
    font-size: 22px;
    color: #1B2228;
  }

  .ant-image {
    margin: 3px 24px 4px 0px;
  }
}

.previousyear-exam-container {
  cursor: pointer;
  width: 100%;
  margin: 0px 18px 12px 30px;
  border-radius: 8px;

  p {
    margin: 0px;
  }

  .exam-name-wrapper {
    width: 100%;
    justify-content: space-around;
  }

  .exam-text {
    .exam-name {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $primary-dark-blue;
      margin: 0px 0px 0px 16px;
      // width: 55%;
      text-transform: capitalize;
    }
  }
}

.previousyear-exam-container:hover {
  // border: 1px solid #B8C3C9;
  // box-shadow: 0px 2px 2px rgba(159, 159, 159, 0.15);
  // border-radius: 5px;
}

.exam-card {
  height: 72px;
  width: 377px;
  cursor: pointer;
  background: #ffffff;
  padding: 10px;
  margin: 0px 18px 12px 0px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e7ea;
}

.previousyear-take-test-component {
  width: 50%;
  background: #FFFFFF;
  border: 1px solid #E1E7EA;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  margin-left: 30px;

  .take-test-content {
    width: 30%;
  }

  .exam-title {
    margin-top: 5px;
    font-weight: 600;
    font-size: 11px;
    color: #1B2228;
    text-transform: capitalize;
  }

  .take-test-component-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

}

.exam-list {
  width: 50%;
  margin-left: 30px;
}


.taketest-share-link {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .shareLinkIcon {
    cursor: pointer;
    border-color: gainsboro;
    display: flex;
    justify-content: end;

    svg {
      height: 30px;
      padding: 7px;
      border: 1px solid gainsboro;
      border-radius: 50%;
    }
  }
}

.shareIcons {
  display: flex;
  justify-content: space-evenly;

  .icon {
    cursor: pointer;
    font-size: 10px;
    margin: 0 10px;
    transition: color 0.3s ease;
    display: flex;
    flex-direction: column;

    svg {
      height: 40px;
      margin: 15px 0;
    }
  }
}
@media (min-width: 992px){
.ant-col-lg-12 {
max-width: max-content;
}
}

.ant-pagination-options {
  display: none;
}
.ant-layout-content{
  margin: 0px !important;
}
.ant-layout-header{
  line-height: 24px !important;
  background: rgb(255, 255, 255);
  position: sticky;
  top: 0;
  z-index: 99;
}
@media screen and (min-width:640px) {
  .my-cart{
    height: 100%;
    padding: 24px;
    .path{
      display:flex;
      // padding:20.5px 0px 0px 24px;
      font-weight:500;
      font-size:12px;
      color:#60717A;
      text-transform: capitalize;
  
      p{
        margin: 0px;
        padding-top:3px;
        padding-right:7px;
        cursor: pointer;
      }
      .ant-image{
        padding-right: 7px;
      }
    }
    .cart-header{
        border: 1px solid #E1E7EA;
        border-radius: 10px;
        background: #FFFFFF;
        padding: 20px ;
        display: flex;
        align-items: center;
        margin: 16px 0px;
        p{
          margin: 0px;
          font-weight: 600;
          font-size: 22px;
          // padding-left: 16px;
          color: #1B2228;
          text-transform: capitalize;
        }
    }
    .cart-banner{
      p{
        margin: 0px;
        font-weight: 500;
        font-size: 18px;
        // padding: 27px 0px 25px 32px;
        color: #000000;
      }
    }
    .cart-list{
      position: relative;
      // margin: 0px 8px 0px 24px;
      // background: #FFFFFF;
      // border: 1px solid #E1E7EA;
      // border-radius: 5px;
      // padding: 16px 32px 0px 0px;
      .cart-item{
        display:flex;
        justify-content:space-between;
        .cart-item-content{
          // display: flex;
           width: 50%;
          p{
            margin: 0;
          }
          .sno{
            font-weight: 600;
            font-size: 14px;
            color: #1B2228;
          }
          .content{
            // margin-left: 27px;
            // padding-bottom: 16px;
            .exam-title{
              margin-top: 5px;
              font-weight: 600;
              font-size: 10px;
              // color: #2D81F7;
              text-transform: capitalize;
  
              span{
                // margin-right: 8px;
                color: #D06200;
                font-weight: 600;
                font-size: 16px;
                // background: rgba(252, 204, 186, 0.1);
                // border: 1px solid #E1C5BB;
                border-radius: 2px;
                padding: 4px 0px;
                text-transform: capitalize;
  
              }
            }
            .chapter-title{
              margin-top: 5px;
              font-weight: 600;
              font-size: 10px;
              // color: #2D81F7;
              text-transform: capitalize;
  
              span{
                // margin-right: 8px;
                color: #5D0AAA;
                font-weight: 600;
                font-size: 10px;
                // background: rgba(155, 81, 224, 0.05);
                // border: 1px solid rgba(155, 81, 224, 0.4);
                border-radius: 2px;
                padding: 4px 0px;
                text-transform: capitalize;
  
              }
              .examName{
                color: #1B2228;
              }
            }
            .full-test-title{
              margin-top: 5px;
              font-weight: 600;
              font-size: 10px;
              // color: #2D81F7;
              text-transform: capitalize;
  
              span{
                // margin-right: 8px;
                color: #D06200;
                font-weight: 600;
                font-size: 10px;
                // background: rgba(252, 204, 186, 0.1);
                // border: 1px solid #E1C5BB;
                border-radius: 2px;
                padding: 4px 0px;
                text-transform: capitalize;
  
              }
              .examName{
                color: #1B2228;
              }
            }
            .test-title{
              font-weight: 600;
              font-size: 14px;
              color: #021F55;
              margin-top: 10px;
              text-transform: capitalize;
  
            }
            .remove-cta{
              font-weight: 500 !important;
              font-size: 12px !important;
              text-decoration-line: underline;
              text-decoration-color: #E1E7EA;
            }
          }
        }
      
      }
      .cart-item-price{
        display: flex;
        margin-top: 16px;
        justify-content: flex-end;
        .price{
          p{   
            margin: 0px;
            font-weight: 600;
            font-size: 14px;}
            color: #021F55;
        }
     
      }
    }
    .ant-divider-horizontal{
      margin: 16px 0 !important;
    }
    .my-cart-content{
      // height: 65%;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin: 12px 0px;
      .my-cart-empty{
        height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      }
    }
  }
  }


  .footer-total-amount{
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #1B2228 !important;
  }
  .amount{
    color: #021F55;
    font-weight: 600;
    font-size: 14px;
    padding-left: 28px;
  }
  .cart-price{
    // margin: 0px 16px;
    margin: 0px 10px;

    padding: 16px;
    // padding: 13px 18px;
    // margin-top: 16px;
    .coupon-container{
      cursor: pointer;
      border: 1px dashed #60717A;
      border-radius: 3px;
      margin: 16px 0px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .use-coupon{
        flex-grow: 1;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: flex-end;
        color: #1B2228;
      }
    }
    .coupon-details{
      border: 1px dashed #60717A;
      border-radius: 3px;
      margin: 16px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      .name{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1B2228;
        padding: 2px 0px;

        
      }
      .text{
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #1B2228;
        padding-top: 5px;
      }
      .link{
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-decoration-line: underline;
        color: #FF4343;
      }
    }

    .bill-wrapper{
      .ant-divider-horizontal{
        margin: 13px 0px;
      }
      .bill-header-text{
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1B2228;
      }
      .itemData{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px 5px;
        .item{
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #37434E;
        }
        .amount{
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: right;
          color: #021F55;
          
        }
      }
      .totalPay{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        .text{
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
          color: #1B2228;
        }
        .amount{
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
          color: #1B2228;
          
        }
      }
    }
    p{
      margin: 0;
    }
    .total{
      // padding: 50px 19px 63px 15px;
      // display: flex;
    }
    .subTotal{
      font-weight: 400;
      font-size: 16px;
      padding-right: 4px;
    }
    .totalAmount{
      // padding-left: 7px;
      font-weight:600;
      font-size: 22px;
      color: #1B2228;
    }
    // p:nth-child(1){
    //   font-weight: 400;
    //   font-size: 16px;
    //   padding-right: 4px;
    // }
    // p:nth-child(2){
    //   // padding-left: 7px;
    //   font-weight:600;
    //   font-size: 22px;
    //   color: #1B2228;
    // }
    background: #FFFFFF;
    border: 1px solid #E1E7EA;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // align-items: center;
    .ant-btn{
      // margin-top: 16px;
      background: #07BD81 !important;
      border-radius: 3px;
      width: 100%;
      &:hover,:focus {
        color: #07BD81;
        border-color: #07BD81;
        background: #fff;
    }
      span{
        color: #FFFFFF;
        font-weight: 500;
        font-size: 12px;
      }
  
    }
  }


  .over-all-cart-item{
    background: #FFFFFF;
    border: 1px solid #E1E7EA;
    border-radius: 5px;
    padding: 16px 16px 0px 16px;
    margin-bottom: 10px;
  }

  .count{
    img{
      cursor: pointer;
    }
    p{
      margin: 0px;
    }
    margin-right: 15px;
    display: flex;
    background: #FFFFFF;
    border: 1.16667px solid #B8C3C9;
    border-radius: 3.5px;
    // width: 93px;
    height: 28px;
    justify-content: space-evenly;
    width: 20%;
    p{
      padding: 0px 12px;
      font-weight: 500;
      font-size: 14px;
      color: #021F55;
      line-height: 26px;
    }
    // .ant-image:nth-child(1){
    //   padding-left: 9px;
    // }
    .ant-image:nth-child(3){
      margin-right: 7px;
      margin-bottom: 3px;

    }
  }
  @media screen and(max-width:640px) {
    .count{
      width: 50%;
    }
  }
  .single-cart-footer{
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    color: #1B2228;
    span{
      font-weight: 600;
      font-size: 14px;
      color: #1B2228;
      margin-left: 16px;
    }
    a{
            font-weight: 500 !important;
            font-size: 12px !important;
            text-decoration-line: underline;
            text-decoration-color: #E1E7EA;
    }
  }
  .my-cart-empty{
    .my-cart-empty-image{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .my-cart-empty-content{
      text-align: center;
      margin-top: 17px;
      font-weight: 400;
      font-size: 14px;
      color: #37434E;
    }
  }

  .subject-title{
    margin-top: 5px;
    font-weight: 600;
    font-size: 10px;
    // color: #2D81F7;
    span{
      // margin-right: 8px;
      color: #039464;
      font-weight: 600;
      font-size: 10px;
      // background: rgba(252, 204, 186, 0.1);
      // border: 1px solid #E1C5BB;
      border-radius: 2px;
      padding: 4px 0px;
    }
    .examName{
      color: #1B2228
    }
  }
  .chapter-title{
    margin-top: 5px;
    font-weight: 600;
    font-size: 10px;
    // color: #2D81F7;
    span{
      // margin-right: 8px;
      color: #5D0AAA;
      font-weight: 600;
      font-size: 10px;
      // background: rgba(155, 81, 224, 0.05);
      // border: 1px solid rgba(155, 81, 224, 0.4);
      border-radius: 2px;
      padding: 4px 0px;
    }
    .examName{
      color: #1B2228
    }
  }
  .full-test-title{
    color: #D06200 !important;
    font-weight: 600;
    font-size: 10px;
    border-radius: 2px;
    text-transform: capitalize;

    span{
      // margin-right: 8px;
      color: #D06200;
      font-weight: 600;
      font-size: 10px;
      // background: rgba(252, 204, 186, 0.1);
      // border: 1px solid #E1C5BB;
      border-radius: 2px;
      padding: 4px 0px;
      text-transform: capitalize;

    }
    .examName{
      color: #1B2228;
      margin-top: 5px;
      font-weight: 600;
      font-size: 10px;
      // color: #021F55;
      text-transform: capitalize;
    }
  }
  @media screen and(max-width:640px) {
    .my-cart{
      height: 100%;
      padding: 24px;
      .path{
        display:flex;
        // padding:20.5px 0px 0px 24px;
        font-weight:500;
        font-size:12px;
        color:#60717A;
        text-transform: capitalize;
    
        p{
          margin: 0px;
          padding-top:3px;
          padding-right:7px;
          cursor: pointer;
        }
        .ant-image{
          padding-right: 7px;
        }
      }
      .cart-header{
          border: 1px solid #E1E7EA;
          border-radius: 10px;
          background: #FFFFFF;
          padding: 20px ;
          display: flex;
          align-items: center;
          margin: 16px 0px;
          p{
            margin: 0px;
            font-weight: 600;
            font-size: 22px;
            // padding-left: 16px;
            color: #1B2228;
            text-transform: capitalize;
          }
      }
      .cart-banner{
        p{
          margin: 0px;
          font-weight: 500;
          font-size: 18px;
          // padding: 27px 0px 25px 32px;
          color: #000000;
        }
      }
      .cart-list{
        position: relative;
        // margin: 0px 8px 0px 24px;
        // background: #FFFFFF;
        // border: 1px solid #E1E7EA;
        // border-radius: 5px;
        // padding: 16px 32px 0px 0px;
        .cart-item{
          // display:flex;
          // justify-content:space-between;
          .cart-item-content{
            display: flex;
            // width: 50%;
            p{
              margin: 0;
            }
            .sno{
              font-weight: 600;
              font-size: 14px;
              color: #1B2228;
            }
            .content{
              // margin-left: 27px;
              // padding-bottom: 16px;
              .exam-title{
                margin-top: 5px;
                font-weight: 600;
                font-size: 10px;
                // color: #2D81F7;
                text-transform: capitalize;
    
                span{
                  // margin-right: 8px;
                  color: #D06200;
                  font-weight: 600;
                  font-size: 10px;
                  // background: rgba(252, 204, 186, 0.1);
                  // border: 1px solid #E1C5BB;
                  border-radius: 2px;
                  padding: 4px 0px;
                  text-transform: capitalize;
    
                }
              }
              .chapter-title{
                margin-top: 5px;
                font-weight: 600;
                font-size: 10px;
                // color: #2D81F7;
                text-transform: capitalize;
    
                span{
                  // margin-right: 8px;
                  color: #5D0AAA;
                  font-weight: 600;
                  font-size: 10px;
                  // background: rgba(155, 81, 224, 0.05);
                  // border: 1px solid rgba(155, 81, 224, 0.4);
                  border-radius: 2px;
                  padding: 4px 0px;
                  text-transform: capitalize;
    
                }
              }
              .full-test-title{
                margin-top: 5px;
                font-weight: 600;
                font-size: 10px;
                // color: #2D81F7;
                text-transform: capitalize;
    
                span{
                  // margin-right: 8px;
                  color: #D06200;
                  font-weight: 600;
                  font-size: 10px;
                  // background: rgba(252, 204, 186, 0.1);
                  // border: 1px solid #E1C5BB;
                  border-radius: 2px;
                  padding: 4px 0px;
                  text-transform: capitalize;
    
                }
                .examName{
                  color: #1B2228;
                }
              }
              .test-title{
                font-weight: 600;
                font-size: 14px;
                color: #021F55;
                margin: 16px 0px;
                text-transform: capitalize;
    
              }
              .remove-cta{
                font-weight: 500 !important;
                font-size: 12px !important;
                text-decoration-line: underline;
                text-decoration-color: #E1E7EA;
              }
            }
          }
        
        }
        .cart-item-price{
          display: flex;
          margin-top: 16px;
          justify-content: flex-end;
          .price{
            p{   
              margin: 0px;
              font-weight: 600;
              font-size: 14px;}
              color: #021F55;
          }
       
        }
      }
      .ant-divider-horizontal{
        margin: 16px 0 !important;
      }
      .my-cart-content{
        // height: 65%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin: 12px 0px;
        .my-cart-empty{
          height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        }
      }
    }
  }
  @media screen and (max-width:640px) {
    .cart-price{
      margin: 0px ;
      padding: 16px;
      p{
        margin: 0;
      }
      .total{
      display: flex;
      justify-content: space-between;
      align-items: center;
      }
      .text{
        font-weight: 400;
        font-size: 16px;
        padding-right: 4px;
      }
      .amount{
        // padding-left: 7px;
        font-weight:600;
        font-size: 22px;
        color: #1B2228;
        text-align: right;
      }
      background: #FFFFFF;
      border: 1px solid #E1E7EA;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      // align-items: center;
      .ant-btn{
        margin-top: 16px;
        background: #07BD81 !important;
        border-radius: 3px;
        span{
          color: #FFFFFF;
          font-weight: 500;
          font-size: 12px;
        }
      }
  
    }
  }
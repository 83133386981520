@import "../variable.scss";

.input-field{
  Input{
    margin: 0px;
    width: 75%;
  }
}
.password-content{
  p{
    font-weight: 500;
    font-size: 12px;
  }
  .password-content-header{
    font-weight: 600;
    font-size: 20px;
    color: #021F55;
  }
}
// .ant-input-password{
//   width: 75% !important;
//   .ant-input{
//     width: 100%;
//   }
// }

.password-container{
  display: flex;
  height: 100vh;
  .passwordImage{
    // background-color: $primary-dark-blue;
 background: url('../assets/images/centumLoginPage.svg') repeat-x center top / cover;

    width: 50%;
  }
  .password-wrapper{
    width: 50%;

    p{
      margin: 0px;
    }
    // padding: 30px 0px;
    margin-top: 20vh;
    .password-content{
      text-align: center;
      .header{
        color: $primary-dark-blue;
        font-weight: 600;
        font-size: 20px;
        padding: 7px;
        margin: 0px;
      }
      .sub-header{
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #1B2228;
      }
    }
    .password-form{
      width: 100%;
      display:flex;
      justify-content: center;
      align-items: center;
      .form-wrapper{
        width: 420px;
        padding: 25px 0px;
        .form-control{
          label{
            font-weight: 500;
            font-size: 12px;
            color: $primary-black;
          }
          .ant-input-password{
            width: 100% !important;
            height: 38px !important;
            padding: 0px 11px;
            margin: 6px 0px;
            .ant-input{
              height: 36px;
              margin: 0px;
            }
          }
          .ant-input{
            width: 100% !important;
            height: 38px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #1B2228;
          }
          .ant-btn{
            width: 100%;
            font-weight: 500;
            font-size: 13px;
            height: 38px;

          } 
          .ant-btn-primary{
            color: #fff;
            border-color: $primary-light-blue;
            background: $primary-light-blue;
          }
          .forgot-link{
            text-align: right;
          }
          .password-button{
            margin: 11px 0px 14px;
          }

  
        }
        .signIn-option{
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: $primary-black;
        }
      }

    }
  }
}
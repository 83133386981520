@import "../variable.scss";


.otp-content{
  p{
    font-weight: 500;
    font-size: 12px;
  }
  .otp-content-header{
    font-weight: 600;
    font-size: 20px;
    color: #021F55;
  }
}

.input-field{
  Input{
    margin-top: 41px;
  }
  Button{
    width: 71%;
    margin-top: 10%;
  }
  p{
    padding-top: 17px;
  }
}
.error{
  // position:absolute ;
  color: red;
  text-align: start;
  // padding-left: 13% !important;
}
.forgot-container{
  display: flex;
  height: 100vh;
  .forgotImage{
    // background-color: $primary-dark-blue;
 background: url('../assets/images/centumLoginPage.svg') repeat-x center top / cover;

    width: 50%;
  }
  .forgot-wrapper{
    width: 50%;

    p{
      margin: 0px;
    }
    // padding: 30px 0px;
    margin-top: 20vh;
    .forgot-content{
      text-align: center;
      .header{
        color: $primary-dark-blue;
        font-weight: 600;
        font-size: 20px;
        padding: 7px;
        margin: 0px;
      }
      .sub-header{
        color: $primary-dark-blue;
        font-weight: 400;
        font-size: 16px;
      }
    }
    .forgot-form{
      width: 100%;
      display:flex;
      justify-content: center;
      align-items: center;
      .form-wrapper{
        width: 420px;
        padding: 25px 0px;
        .form-control{
          label{
            font-weight: 500;
            font-size: 12px;
            color: $primary-black;
          }
          .ant-input-password{
            width: 100% !important;
            height: 38px !important;
            padding: 0px 11px;
            margin: 6px 0px;
            .ant-input{
              height: 36px;
              margin: 0px;
            }
          }
          .ant-input{
            width: 100% !important;
            height: 38px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #1B2228;
          }
          .ant-btn{
            width: 100%;
            font-weight: 500;
            font-size: 13px;
            height: 38px;

          } 
          .ant-btn-primary{
            color: #fff;
            border-color: $primary-light-blue;
            background: $primary-light-blue;
          }
          .forgot-link{
            text-align: right;
          }
          .forgot-button{
            margin: 11px 0px 14px 0px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
          }

  
        }
        .navigate{
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: #1b2228;
        }
        .signIn-option{
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: $primary-black;
        }
      }

    }
  }
}

.otp-container{
  display: flex;
  height: 100vh;
.otpImage{
  // background-color: $primary-dark-blue;
 background: url('../assets/images/centumLoginPage.svg') repeat-x center top / cover;

  width: 50%;
}
  .otp-wrapper{
    width: 50%;
    p{
      margin: 0px;
    }
    // padding: 30px 0px;
    margin-top: 20vh;
    .otp-content{
      text-align: center;
      .header{
        color: $primary-dark-blue;
        font-weight: 600;
        font-size: 20px;
        padding: 7px;
        margin: 0px;
      }
      .sub-header{
        color: #1B2228;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;


      }
    }
    .otp-form{
      width: 100%;
      display:flex;
      justify-content: center;
      align-items: center;
      .form-wrapper{
        width: 420px;
        padding: 25px 0px;
        .form-control{
          label{
            font-weight: 500;
            font-size: 12px;
            color: $primary-black;
          }
          .ant-input-password{
            width: 100% !important;
            height: 38px !important;
            padding: 0px 11px;
            margin: 6px 0px;
            .ant-input{
              height: 36px;
              margin: 0px;
            }
          }
          .ant-input{
            width: 100% !important;
            height: 38px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #1B2228;
          }
          .ant-btn{
            width: 100%;
            font-weight: 500;
            font-size: 13px;
            height: 38px;

          } 
          .ant-btn-primary{
            color: #fff;
            border-color: $primary-light-blue;
            background: $primary-light-blue;
          }
          .otp-link{
            text-align: right;
          }
          .otp-button{
            margin: 11px 0px;
          }
          .login-button{
            margin: 11px 0px 14px 0px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
          }
  
        }

        .navigate{
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: #1b2228;
        }
        .signIn-option{
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: $primary-black;
        }
      }

    }
  }
}

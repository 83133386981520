/* batch test result */
.batch-test-result-list {
  p{
    margin-bottom: 1px;
  }
    padding: 16px;
    .heading-back-icon {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      justify-content: space-between;
      .image {
        cursor: pointer;
        display: flex;
      }
  
      .test-name {
        margin-left: 8px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        cursor: text;
      }
    }
  
    .batch-test-result-content {
      padding: 20px;
      border-radius: 8px;
      background: #FFF;
      margin-bottom: 18px;
  
      .test-content {
        margin-bottom: 28px;
  
        .exam-group-name {
          font-size: 11px;
          font-weight: 500;
          color: #2D81F7;
          // margin-bottom: 12px;
        }
  
        .test-name {
          font-size: 20px;
          font-weight: 600;
          color: #1B2228;
          // margin-bottom: 12px;
        }
  
        .divition-tag {
          height: 2px;
          width: 100%;
          background: #E1E7EA;
          margin-bottom: 12px;
        }
  
        .test-details {
          display: flex;
          justify-content: space-between;
          width: 90%;
  
          .icon-section {
            display: flex;
            margin-top: 8px;
  
            p {
              margin-left: 8px;
              font-size: 14px;
              font-weight: 500;
              color: #37434E;
            }
          }
  
          .mark-section {
            .marks {
              font-size: 25px;
              font-weight: 600;
              color: #1B2228;
            }
  
            .marks-label {
              font-size: 16px;
              font-weight: 500;
              color: #37434E;
            }
          }
  
          .mark-language {
            .total-student {
              font-size: 13px;
              font-weight: 500;
              color: #37434E;
            }
          }
        }
      }
  
      .mark-content {
  
        .section-1,
        .section-2 {
          display: flex;
          gap: 40px;
          // justify-content: space-between;
  
          .mark-card {
            padding: 9px 14px;
            display: flex;
            align-items: center;
            border-radius: 8px;
            background: #FFF;
            border: 1px solid #E1E7EA;
            width: 30%;
  
            .text-section {
              margin-left: 20px;
  
              .label {
                font-size: 13px;
                color: #37434E;
                font-weight: 500;
              }
  
              .value {
                font-size: 24px;
                font-weight: 600;
                color: #1B2228;
              }
            }
          }
        }
  
        .section-1 {
          margin-bottom: 16px;
        }
      }
    }
  
    .Leader-board-content {
      margin-bottom: 20px;
  
      .heading {
        font-size: 16px;
        color: #1B2228;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .my-rank-card {
        display: flex;
        justify-content: start;
        margin-bottom: 16px;
        .rank-card {
          display: flex;
          padding: 0px 16px;
          border-radius: 6px;
          width: 32%;
          align-items: center;
          margin-right: 12px;
      }
      .name-mark {
        // margin-left: 14px;

        .name {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          // margin-bottom: 8px;
          color: #242424;
          text-transform: capitalize;
        }

        .mark {
          font-size: 24px;
          font-weight: 600;
          line-height: 20px;
          color: #1B2228;

          span {
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            color: #60717A;
          }
        }
      }
      .my-rank{
        background: #FFFFFF;
       margin-right: 0;
       height: 84px;
       gap: 10px;
      }
    }
      .first-three-rank {
        display: flex;
        justify-content: start;
        margin-bottom: 16px;
  
        .rank-card {
          display: flex;
          padding: 0px 16px;
          border-radius: 6px;
          width: 32%;
          align-items: center;
          margin-right: 12px;
  
           
          
  
          .name-mark {
            // margin-left: 14px;
  
            .name {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              // margin-bottom: 8px;
              color: #242424;
              text-transform: capitalize;
            }
  
            .mark {
              font-size: 24px;
              font-weight: 600;
              line-height: 20px;
              color: #1B2228;
  
              span {
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                color: #60717A;
              }
            }
          }
        
        }
  
        .first-rank {
          background: rgba(45, 129, 247, 0.10);
        }
  
        .second-rank {
          background: #FEF9C3;
        }
  
        .third-rank {
          background: #E9D5FF;
         margin-right: 0;
        }
       
      }
  
      .table-section-rank {
        .ant-table-thead {}
      }
    }
  }
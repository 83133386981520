.nav{
  // padding-bottom: 0.3%;
  // top: 0;
  // z-index: 1;
  // position: fixed;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  .nav-content{
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    a{
      font-weight: 450;
      font-size: 15px;
      display: flex;
      align-items: center;
      color: #1B2228;
      .anticon{
        padding: 2px 0px 0px 4px;
      }
    }
  }
}
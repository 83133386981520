$primary-black: #1b2228;
$primary-grey: #37434e;
$primary-dark-blue: #021f55;
$primary-light-blue: #2d81f7;
$primary-green: #07bd81;
$primary-white: #ffffff;
$secondary-white:#F9F9F9;
$primary-red: #ff4343;
$secondary-grey: #60717a;
$third-level-grey:#7A8B94;
$fourth-level-grey:#B8C3C9;
$border-grey-color: #e1e7ea;
$primary-orange:#FFA443;
$primary-background-color:#F5F6F8;
$secondary-light-blue:#DFECFF;
$primary-light-pink:rgba(252, 204, 186, 0.1);
$border-pink-color:#E1C5BB;
$primary-dark-orange:#D06200;
$primary-purple:#5D0AAA;
$primary-purple-background:rgba(155, 81, 224, 0.05);
$primary-purple-border:rgba(155, 81, 224, 0.4);
$primary-green-background:rgba(7, 189, 129, 0.05);
$primary-green-border:rgba(8, 151, 104, 0.2);
$secondary-green:#039464;
$drawer-footer-background-color:#F5F5F5;
$fifth-level-grey:#F3F5F8;
$incorrect-background-color:rgba(255, 67, 67, 0.08);
$correct-background-color:rgba(7, 189, 129, 0.08);
$incorrect-radio-background-color:#EA4C89;


@font-face {
 font-family: "Montserrat";
 src: url(../fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
 font-weight: 400;
}
@font-face {
 font-family: "Montserrat";
 src: url(../fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
 font-weight: 500;
}
@font-face {
 font-family: "Montserrat";
 src: url(../fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
 font-weight: bold;
}
#root {
 font-family: "Montserrat";
}
body {
 font-family: "Montserrat";
}

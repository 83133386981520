@import "../variable.scss";

.overall-login{
  margin-top: 5%;
  position:'relative';
  text-align:center;
  .ant-input{
    width: 75%;
  }
  Button{
    width: 75%;
    margin-top: 45px;
  }
  .ant-image-img{
    margin-top: 16%;
    margin-bottom: 27.04px;
  }
  .ant-input-affix-wrapper{
    width: 75%;
    .ant-input{
      width: 100%;
    }
  }
}

.login-container{
  display: flex;
  height: 100vh;
.loginImage{
 background: url('../assets/images/centumLoginPage.svg') repeat-x center top / cover;
  width: 50%;
}
  .login-wrapper{
    width: 50%;
    p{
      margin: 0px;
    }
    // padding: 30px 0px;
    margin-top: 20vh;

    .login-content{
      text-align: center;
      .header{
        color: $primary-dark-blue;
        font-weight: 600;
        font-size: 20px;
        padding: 7px;
        margin: 0px;
      }
      .sub-header{
        color: $primary-dark-blue;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .login-form{
      width: 100%;
      display:flex;
      justify-content: center;
      align-items: center;
      .form-wrapper{
        width: 420px;
        padding: 25px 0px;
        .form-control{
          label{
            font-weight: 500;
            font-size: 12px;
            color: $primary-black;
          }
          .ant-input-password{
            width: 100% !important;
            height: 38px !important;
            padding: 0px 11px;
            margin: 6px 0px;
            .ant-input{
              height: 36px;
              margin: 0px;

            }
          }
          .ant-input{
            width: 100% !important;
            height: 38px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #1B2228;
          }
          .ant-btn{
            width: 100%;
            font-weight: 500;
            font-size: 13px;
            height: 38px;

          } 
          .ant-btn-primary{
            color: #fff;
            border-color: $primary-light-blue;
            background: $primary-light-blue;
          }
          .forgot-text{
            text-align: right;
          }
          .forgot-link{
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            // color: #37434E;
          }
          .login-button{
            margin:11px 0px 14px 0px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
          }

  
        }
        .signIn-option{
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          color: $primary-black;
        }
      }

    }
  }
 
 .institution-form-control{
  
  input {
    // height: 42px !important;
    border: 1px solid #7A8B94;
border-radius: 3px;
&:focus{
  box-shadow: none;
}
  }
  .ant-input-password{
    // height: 42px !important;
    border: 1px solid #7A8B94;
border-radius: 3px;
&:focus{
  box-shadow: none;
}
  }
 }
}